import axios from "axios";
var messageMap = new Map();
var getMapData = function (callback) {
  const dataUrl = "/json/messagebox.json";
  axios.get(dataUrl).then(function (res) {
    for (let i = 0; i < res.data.length; i++) {
      messageMap.set(res.data[i].id, res.data[i].content);
    }
    callback(messageMap);
  }).catch(function (error) {
    console.log(error);
  });
};
export { getMapData };