/*
 * @Author: 617262278@qq.com
 * @Date: 2023-03-05 01:07:53
 * @LastEditTime: 2023-10-30 10:55:15
 * @FilePath: /website/src/store/index.js
 * @Description: vuex 数据存储
 */
import Vue from "vue";
import Vuex from "vuex";
Vue.use(Vuex);
export default new Vuex.Store({
  state: {
    // 原定义
    userId: "",
    navClass: sessionStorage.getItem("navClass") ? sessionStorage.getItem("navClass") : 2,
    // 跟新头部导航样式
    audioBox: {
      height: sessionStorage.getItem("audioBoxHeight") ? sessionStorage.getItem("audioBoxHeight") : 0,
      top: sessionStorage.getItem("audioBoxHeight") ? sessionStorage.getItem("audioBoxHeight") - 74 : 0
    },
    //用于做屏幕适配
    loginModal: false,
    isLoginOut: 0,
    // 补充定义
    // 一些开关

    // 简单变量
    pauseTimer: 0,
    //记录当前播放的时间点位，即seek值
    processTime: 0,
    //实时播放时间

    // 3D画布相关
    flashValList: [],
    //记录所有小球实时闪动值
    smallSphereList: [],
    isRender: true,
    //是否开启渲染
    trackIdAngleMap: new Map(),
    // trackId与立体声小球组夹角的map关系

    avatarUrl: "",
    //头像

    isFirstLoad: true
  },
  mutations: {
    SET_USERID: (state, value) => {
      state.userId = value;
    },
    SET_NAVCLASS: (state, value) => {
      state.navClass = value;
    },
    SET_AUDIOBOX: (state, value) => {
      state.audioBox = value;
    },
    SET_LOGINMODAL: (state, value) => {
      state.loginModal = value;
    },
    SET_LOGINOUT: (state, value) => {
      state.isLoginOut = value;
    },
    getPauseTimer(state, value) {
      state.pauseTimer = value;
    },
    setProcessTime(state, value) {
      state.processTime = value;
    },
    setFlashValList(state, value) {
      state.flashValList = value;
    },
    setSmallSphereList(state, value) {
      state.smallSphereList = value;
    },
    setIsRender(state, value) {
      state.isRender = value;
    },
    setTrackIdAngleMap(state, value) {
      state.trackIdAngleMap = value;
    },
    setAvatarUrl(state, value) {
      state.avatarUrl = value;
    },
    setFirstLoad(state, value) {
      state.isFirstLoad = value;
    }
  },
  getters: {
    userId: state => state.userId,
    navClass: state => state.navClass
  },
  actions: {
    SET_NAVCLASS_ACTIVE(store, value) {
      store.commit("SET_NAVCLASS", value);
    },
    FedLogOut({
      commit
    }) {
      return new Promise(() => {
        commit("SET_LOGINMODAL", true);
      });
    }
  },
  modules: {}
});