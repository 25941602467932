export default {
  nav: {
    immersiveAudio: "沉浸式音频",
    interactiveAudio: "互动式音频",
    creator: "创作者",
    resources: "资源社区",
    aboutOur: "关于我们"
  },
  common: {
    open: "打开",
    openVoice: "打开声音",
    enterPhoneNumber: "请输入手机号",
    enterPhoneError: "手机号码格式不正确",
    enterPassword: "输入密码",
    forgetPassword: "忘记密码",
    accountLogin: "账号登录",
    resetting: "重置",
    enterNewPassword: "输入新密码",
    passwordAgain: "再次输入新密码",
    changePasswordIdentity: "修改密码时，需要验证身份",
    needs: "您的需求",
    needsDescription: "留下您的需求，我们会在第一时间与您取得联系！",
    cancel: "取消",
    confirm: "确定",
    freeUse: "免费使用",
    operatingGuide: "使用指南",
    example: "示例",
    readMore: "查看更多",
    login: "登录",
    register: "注册",
    thirdPartyLogin: "第三方登录",
    wechatLogin: "微信登录",
    noAccount: "还没有账号",
    quickRegistration: "快速注册",
    hasAccount: "已有账号",
    loginImmediately: "立即登录",
    enterCode: "输入验证码",
    digitCode: "4位数字验证码",
    getCode: "获取验证码",
    mobilePhoneNumbersAre: "目前仅支持大陆地区手机号",
    bindingMobilePhone: "绑定手机号",
    cooperativePartner: "合作伙伴",
    personalHomepage: "个人主页",
    GoToStudio: "前往工作室",
    logOff: "退出登录",
    pleaseMailbox: "请输入邮箱",
    mailboxFormed: "邮箱格式不正确",
    enterKeyword: "请输入关键词",
    copyUrl: "复制网址",
    url: "网址",
    noData: "暂无数据",
    updatedAtAbout: "更新于",
    ago: "前",
    justUpdated: "刚刚更新",
    minute: "分钟",
    hour: "小时",
    Days: "天",
    playVideo: "播放视频"
  },
  linkDescription: ["建议使用电脑体验此功能，效果更佳!", "请复制下方网址，转至电脑"],
  footerNav: {
    contactUsDescription: "探索沉浸式音频世界，WANOS全景声业务洽谈请联系我们。",
    introduction: "公司介绍",
    servicePolicy: "服务协议",
    aboutUs: "关于我们",
    privacyPolicy: "隐私协议",
    companyProfile: "公司简介",
    followUs: "关注我们",
    wechatAccount: "微信公众号",
    weiboAccount: "官方微博",
    contactUs: "联系我们",
    businessCooperation: "商务合作",
    businessConsulting: "企业客户咨询",
    communityGroup: "社区交流群",
    feedback: "问题反馈",
    wechat: "微信",
    feedbackQuestions: "反馈问题",
    scanQRCode: "扫描二维码",
    communicationGroup: "加入社区交流群",
    followWechatOfficialAccount: "关注微信公众号",
    shareFriends: "即可分享给朋友"
  },
  immersiveAudio: {
    soundExperience: "无与伦比的声音体验",
    soundExperienceDescription: "三维声场，让声音来自四面八方，包括头顶声音由远入微，更生动更具细节",
    newDimension: "开启声音的新维度",
    newDimensionDescription: "挖掘更多声音维度，清晰辨别声音发声位置及  轨迹，还原现场效果，为声音注入灵魂",
    entertainmentScene: "身临其境的娱乐场景",
    entertainmentSceneDescription: "电影、游戏、音乐等任何场景下，感受声音的极致沉浸体验，获得身临其境的氛围感",
    WANOSMovie: "影视全景声",
    WANOSMovieDescription: "WANOS音频技术为电影行业提供完整的全景声解决方案，包括内容制作工具、影院设备和服务，不断推动电影艺术向前发展。截至2022年，已有70余部WANOS全景声电影上映，全国300多家影院运用了WANOS全景声技术。",
    WANOSMusic: "音乐全景声",
    WANOSMusicDescription: "声音环绕在您的四周，细致入微的感受人声与乐器的每个细节，充分还原创作者的情感表达，栩栩如生的逼真声音效果，仿佛置身于现场。",
    WANOSCar: "汽车全景声",
    WANOSCarDescription: "为汽车制造商提供强力支持，帮助汽车打造完善的生态系统，设计构建浑然一体的沉浸听觉空间，充分发挥车载音频优势，打造前所未有的车载娱乐体验。灵动的声音细节、无与伦比的声音体验， 宛如将大自然装进车内，让驾驶者更加享受车内时光， 充分沉浸在全景声的世界。"
  },
  interactiveAudio: {
    feelChange: "在运动中体验互动式音频的不同之处！",
    headphoneExperience: "请您带上耳机体验，效果更佳哦～",
    immediateExperience: "立即体验",
    spatialPositioning: "空间定位",
    spatialPositioningDescription: "在三维空间中精准定位声音位置、方向、距离，当产生改变时能清晰还原声音的运动轨迹，更接近真实世界，强化沉浸感受",
    interactiveExperience: "互动式体验",
    interactiveExperienceDescription: "  WANOS空间音频渲染引擎实现了更高维度的声音建模，基于声音对象和虚拟场景技术，声音会根据您的头部朝向、音源朝向、远近距离、空间位置等变化，呈现不同声音效果，使人耳能够定位声源的传播方向和传播距离",
    soundfieldSimulation: "声场模拟",
    soundfieldSimulationDescription: "小到房间，大到电影院、山谷、平原等，随着场景变化，实时还原符合场景的声场，从而提升听觉体验",
    gameSpatialAudio: "游戏空间音频",
    gameSpatialAudioDescription: [" 沉浸在更真实的游戏体验中用逼真的音效将自己带入游戏世界", " 生存更久、提高分数、赢得胜利 更准确地定位潜行的敌人，避免呼啸而过的子弹，并检测附近的威胁", " 保持头脑清醒，玩得更久通过将声音明显远离您来减少耳朵和大脑的疲劳", "WANOS空间音频，独特的游戏调音 自定义创建逼真的声学效果，最大限度真实还原游戏场景"],
    spatialAudio: "AR、VR、MR空间音频",
    spatialAudioDescription: [" 沉浸在更真实的虚拟世界具有几乎无限数量的声源空间渲染", " 参与对话和互动更好的音频质量、更低的延迟、同步音频流为用户提供更好的对话互动体验", " 空间和氛围创建更加真实的声学虚拟场景", " 动态渲染 接近现实的实时动态渲染，可选择HRTF个性化和扬声器渲染", " 空间音频播放器播放WANOS音频格式，将全景声内容在虚拟世界中呈现"]
  },
  creator: {
    soundCreators: "谁是身临其境的声音创作者？",
    description: "从音频爱好者到格莱美获奖艺术家，任何人都可以使用WANOS进行创作。 支持有创造力的创新者是我们前进的动力。",
    webStudioDescription: " WANOS WEB STUDIO 是一个强大的全景声音频创作工具，用于创作、写作和交付。我们重新设计了全景声音频创作方式，无论经验如何，都可以很简单的完成创作。同时借助于设计精美和随时可编辑的模板，几分钟内便可以完成全景声音频创作。",
    quickStartGuide: "快速入门指南",
    quickStartGuideDescription: " 我们的快速入门指南将引导您了解一些基础知识和使用方法，让您立刻开启创作之旅。",
    artist: "艺术家",
    artistDescription: ["对于视频制作，视觉的呈现仅是艺术家讲述故事的一部分。", "而WANOS为每一个精彩的画面注入声音灵魂， 让声音为视觉带来更深层次的表现空间，更具艺术感染力。", "声音的真实感触达每个细胞，与画面完美融合，令创作更加完整丰富，达到视觉与听觉的双重震撼。", " WANOS为艺术家、声音设计师和作曲家提供了极为精简的声音解决方案。使他们能更便捷、更灵活的创作出三维全景声内容。"],
    creativeDesign: "创意设计",
    creativeDesignDescription: [" 在电影、音乐、游戏等场景，声音设计已发展多年，仍属于专业领域设计。而全新的全景声云制作平台能够让非专业人士也同样享受创作的乐趣。", " WANOS让声音创作比任何时候更专业、更简单、更快速，为声音爱好者提供令人惊叹的全景声音频创作服务。"],
    amateurStudioSettings: "业余爱好者工作室设置",
    amateurStudioSettingsDescription: " 借助我们的空间音频双耳渲染算法，创作者现在只需一副耳机即可进行全景声音频创作。创作者可以通过改变声音在空间中的位置，实时预览全景声音频效果。",
    professionalSettings: "专业工作室设置",
    professionalSettingsDescription: " 音频专业人士只需要将WANOS集成到工作流程中，在“我的工作室”中将音频输出设备进行配置即可完成扬声器阵列设置，从而开启全景声音频创作。",
    professionalSettingsExample: "通过在天花板上增加 4 个扬声器，工作室从 7.1 环绕环境增强到 7.1.4全景声部署，即可完成满足全景声的硬件配置。7.1.4扬声器布局分别是前面3个、侧环绕2个、 后面2个、天空4个和低音炮1个。",
    coolThings: "您可以在WANOS中做很酷的事情",
    creativity: " 在资源社区中探索无限创意"
  },
  resources: ["高质量音效库、音乐模版", "海量资源为您的创意工作注入活力"],
  aboutOur: {
    companyIntroduction: "公司介绍",
    aboutUs: " WANOS全景声科技作为世界前沿的音频技术领创者，融合了声音对象、空间音频等技术，创造出全新的三维沉浸式听觉体验，让听众身临其境的感受视听作品的逼真效果和创作意境。",
    foundingTeam: "创始团队",
    foundingTeamDescription: ["全景声科技的创始团队由多名国际知名的音频技术专家组成，拥有20余年国际音频技术研究、标准化、产业化经验，为音频技术领导创新者、国家标准、国际标准和行业标准的制定者、全景声及空间音频技术开创者。", "创始⼈潘兴德博⼠是全景声和空间音频技术最早的探索者之一，AVS⾳频组的创始组⻓，现任AVS⾳频联合组⻓和 IEEE VR⾳频标准的召集⼈。"],
    CEOName: "潘兴德",
    CEOJob: "创始人&CEO",
    ourInvestors: "我们的投资者",
    CEODescription: ["北京邮电大学博士", "中国音视频标准化工作组(AVS)音频创始组长", "负责全景声和空间音频的核心技术研发、标准化和产业化"]
  }
};