var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    ref: "containerRN",
    staticClass: "containerRN"
  }, [_c('div', {
    staticClass: "settingTitle"
  }, [_vm._v("修改名称")]), _c('div', {
    staticClass: "xArea",
    on: {
      "click": _vm.disRN
    }
  }, [_c('p', {
    staticClass: "xIcon"
  }, [_c('svg-icon', {
    attrs: {
      "icon-class": "close",
      "class-name": "f16"
    }
  })], 1)]), _c('div', {
    staticClass: "lineTop"
  }), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.accountInfo.username,
      expression: "accountInfo.username"
    }],
    ref: "inputStyle",
    staticClass: "inputStyle",
    attrs: {
      "placeholder": "请输入账户名称"
    },
    domProps: {
      "value": _vm.accountInfo.username
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.accountInfo, "username", $event.target.value);
      }
    }
  }), _c('div', {
    staticClass: "lineBottom"
  }), _c('div', {
    staticClass: "cancelButton tac",
    on: {
      "click": _vm.disRN
    }
  }, [_vm._v(_vm._s(_vm.$t("common.cancel")))]), _c('div', {
    staticClass: "sureButton tac",
    on: {
      "click": _vm.saveName
    }
  }, [_vm._v("保存")])]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };