var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', [_c('header-top', {
    attrs: {
      "isWxLogin": _vm.isWxLogin
    }
  }), _c('keep-alive', [_c('router-view')], 1), _vm.isShow ? _c('div', [_c('cooperative-partner'), _c('footer-bottom')], 1) : _vm._e(), _c('el-dialog', {
    attrs: {
      "before-close": _vm.handleCloseActivity,
      "visible": _vm.dialogVisibleActivity,
      "close-on-click-modal": false
    },
    on: {
      "update:visible": function ($event) {
        _vm.dialogVisibleActivity = $event;
      }
    }
  }, [_c('div', {
    staticClass: "join-activity",
    on: {
      "click": _vm.linkActivity
    }
  })])], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };