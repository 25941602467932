import "../../assets/css/index.css";
export default {
  name: "Login",
  data() {
    return {};
  },
  mounted() {},
  methods: {
    closeModal() {
      this.$store.commit("SET_LOGINMODAL", false);
    }
  }
};