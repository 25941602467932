var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    class: ['nav_bax', _vm.navClass == 1 ? 'nav_bg1' : _vm.navClass == 2 ? 'nav_bg2' : 'nav_bg3']
  }, [_c('div', {
    staticClass: "nav_content"
  }, [_c('div', {
    staticClass: "nav_left"
  }, [_vm.type ? _c('div', {
    class: ['nav_logo', _vm.navClass == 2 ? 'nav_logo2' : 'nav_logo1', 'pointer'],
    on: {
      "click": function ($event) {
        return _vm.handleClick(0);
      }
    }
  }) : _c('div', {
    class: ['nav_logo', _vm.navClass == 2 ? 'nav_logo2' : 'nav_logo1']
  })]), _c('div', {
    staticClass: "nav_wrap flex jc_center"
  }, _vm._l(_vm.navList, function (item, index) {
    return _c('div', {
      key: index + 'nav',
      class: ['nav_wrap_item', _vm.type == index ? 'nav_active' : '', 'f14', 'fw500', 'pr48', 'pointer'],
      on: {
        "click": function ($event) {
          return _vm.handleClick(index);
        }
      }
    }, [_c('span', {
      staticClass: ""
    }, [_vm._v(_vm._s(_vm.$t("nav." + item.text)))])]);
  }), 0), _c('div', {
    staticClass: "nav_right flex ai_center"
  }, [_c('Poptip', {
    attrs: {
      "trigger": "hover",
      "placement": "bottom-end"
    },
    scopedSlots: _vm._u([{
      key: "content",
      fn: function () {
        return [_c('div', {
          staticClass: "language"
        }, _vm._l(_vm.languageOptions, function (item) {
          return _c('p', {
            key: item.value,
            on: {
              "click": function ($event) {
                return _vm.languageOptionsChange(item.value);
              }
            }
          }, [_vm._v(" " + _vm._s(item.label) + " ")]);
        }), 0)];
      },
      proxy: true
    }])
  }, [_c('svg-icon', {
    attrs: {
      "icon-class": "language",
      "class-name": "f22"
    }
  })], 1), !_vm.isLogin ? _c('div', {
    staticClass: "nav_btns f14 fw500 flex ml20"
  }, [_c('div', {
    staticClass: "pt10 pb10 pl28 pr28 br4 colfff nav_btn_register pointer",
    on: {
      "click": function ($event) {
        return _vm.handleLogin('login');
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("common.register")) + "/" + _vm._s(_vm.$t("common.login")) + " ")])]) : _c('div', {
    staticClass: "nav_btns f14 fw500 flex jc_center ai_center pointer"
  }, [_c('div', {
    class: ['f14', 'fw500'],
    staticStyle: {
      "line-height": "40px",
      "width": "118px"
    },
    on: {
      "click": function ($event) {
        return _vm.handleOpenEdit();
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("common.GoToStudio")) + " ")]), _c('div', [_c('div', {
    staticClass: "nav_header"
  }, [_c('Dropdown', {
    attrs: {
      "placement": "bottom-end"
    },
    scopedSlots: _vm._u([{
      key: "list",
      fn: function () {
        return [_c('DropdownMenu', [_c('DropdownItem', [_c('p', {
          staticClass: "liStyleR liStyleT",
          on: {
            "click": function ($event) {
              return _vm.handleClick(5);
            }
          }
        }, [_vm._v(" " + _vm._s(_vm.$t("common.personalHomepage")) + " ")])]), _c('DropdownItem', [_c('p', {
          staticClass: "liStyleR",
          on: {
            "click": _vm.signOut
          }
        }, [_vm._v(" " + _vm._s(_vm.$t("common.logOff")) + " ")])])], 1)];
      },
      proxy: true
    }])
  }, [_c('div', {
    staticClass: "nav_header_img pointer"
  }, [_vm.$store.state.avatarUrl ? _c('img', {
    attrs: {
      "src": _vm.$store.state.avatarUrl,
      "alt": ""
    }
  }) : _c('img', {
    attrs: {
      "src": "/home/images/icon/header.png",
      "alt": ""
    }
  })])])], 1)])])], 1)]), _c('personal'), _c('Modal', {
    attrs: {
      "lock-scroll": true,
      "styles": {
        top: '20%'
      },
      "transition-names": ['vertical-center-modal', 'vertical-center-modal'],
      "class-name": "vertical-center-modal bgC",
      "title": "Title",
      "width": "425"
    },
    on: {
      "on-visible-change": _vm.onChange
    },
    model: {
      value: _vm.isModal,
      callback: function ($$v) {
        _vm.isModal = $$v;
      },
      expression: "isModal"
    }
  }, [_c('Login', {
    attrs: {
      "fatherProps": _vm.isModal,
      "isModal": _vm.isModal
    }
  })], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };