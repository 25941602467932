import "../../assets/css/index.css";
import "../../assets/css/login.css";
import Header from "@/components/login/Header";
import LoginCom from "@/components/login/LoginCom";
import Register from "@/components/login/Register";
import Reset from "@/components/login/Reset";
import { wxLogin } from "../../assets/js/api/axiosApi";
export default {
  name: "Login",
  props: ["fatherProps"],
  components: {
    "header-component": Header,
    "login-component": LoginCom,
    "register-component": Register,
    "reset-component": Reset
  },
  data() {
    return {
      isLogin: true,
      isRegister: false,
      isReset: false,
      otherModel: true
    };
  },
  watch: {
    fatherProps() {
      if (this.fatherProps) {
        this.isLogin = true;
        this.isRegister = false;
        this.isReset = false;
      }
    }
  },
  created() {
    // let type = this.$route.params.type
    this.switchState("login");
  },
  mounted() {
    // let code;
    // if (document.URL.split("").indexOf("?") !== -1) {
    //   code = this.getQueryString("code");
    // }
    let code;
    let url = document.URL;
    const regex = /code=([^&]+)/;
    const match = url.match(regex);
    if (match && match[1]) {
      code = match[1];
    }
    if (code) {
      wxLogin(code, "", "", "", res => {
        if (res.code === 10000) {
          if (!res.data.user.mobile) {
            this.$store.commit("SET_LOGINMODAL", true);
            setTimeout(() => {
              this.switchState("bindingPhone");
              this.changeReStyle(false);
            }, 10);
          } else if (res.data.token) {
            let vdata = res.data;
            //这个result == 200 是登录状态
            localStorage.setItem("result", 200);
            localStorage.setItem("email", vdata.user.email);
            localStorage.setItem("openid", vdata.user.openid);
            localStorage.setItem("nation", vdata.user.nation);
            localStorage.setItem("phone", vdata.user.mobile);
            localStorage.setItem("id", vdata.user.id);
            localStorage.setItem("name", vdata.user.username);
            localStorage.setItem("token", vdata.token);
            this.$store.commit("setAvatarUrl", vdata.user.avatar);
            this.$store.commit("SET_LOGINOUT", "");
            this.$store.commit("SET_LOGINMODAL", false);
          }
        }
      });
    }
  },
  methods: {
    getQueryString(name) {
      let reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
      let url = window.location.href.split("?")[1].match(reg);
      if (url != null) {
        return decodeURI(url[2]); //decodeURI() 函数可对 encodeURI() 函数编码过的 URI 进行解码。
      } else {
        return null;
      }
    },
    changeLoginStyle(isWx) {
      if (isWx) {
        this.$refs.loginStyle.style.height = "608px";
      } else {
        this.$refs.loginStyle.style.height = "558px";
      }
    },
    changeReStyle(isWx) {
      if (isWx) {
        this.$refs.reStyle.style.height = "608px";
      } else {
        this.$refs.reStyle.style.height = "642px";
      }
    },
    switchState(state) {
      if (state == "register") {
        this.isLogin = false;
        this.isRegister = true;
        this.isReset = false;
      } else if (state == "login") {
        this.isLogin = true;
        this.isRegister = false;
        this.isReset = false;
      } else if (state == "reset") {
        this.isLogin = false;
        this.isRegister = false;
        this.isReset = true;
        setTimeout(() => {
          this.$refs.resetStyle.style.height = "33.5rem";
        });
      } else if (state == "bindingPhone") {
        this.isLogin = false;
        this.isRegister = true;
        this.isReset = false;
        this.otherModel = false;
      }
    }
  }
};