import "../../assets/css/index.css";
import { resetPassword, getVerCode } from "../../assets/js/api/axiosApi";
import { getMapData } from "../../assets/js/api/messageMap";
export default {
  name: "Reset",
  props: ["isModal", "changeLoginStyle"],
  data() {
    return {
      iphoneVal: null,
      passwordVal: null,
      verCodeVal: null,
      isShowError_null: false,
      isShowError_ipone: false,
      textVal: this.$t("common.getCode"),
      isPostCode: true,
      passwordWrong: ""
    };
  },
  watch: {
    isModal() {
      if (!this.isModal) {
        this.iphoneVal = "";
        this.passwordVal = "";
        this.verCodeVal = "";
        this.isShowError_null = false;
        this.isShowError_ipone = false;
        this.passwordWrong = "";
      }
    }
  },
  mounted() {},
  methods: {
    async login() {
      await this.$parent.switchState("login");
      this.changeLoginStyle(false);
    },
    // 只能输入英文、数字
    btKeyDown(e) {
      e.target.value = e.target.value.replace(/\s*/g, "");
    },
    //限制输入特殊字符
    btKeyUp(e) {
      e.target.value = e.target.value.replace(/[`~!@#$%^&*()_\-+=<>?:"{}|,./;'\\[\]·~！@#￥%……&*（）——\-+={}|《》？：“”【】、；‘'，。、]/g, "");
    },
    passwordChange() {
      this.passwordVal = this.passwordVal.replace(/\s+/g, "");
      this.passwordWrong = this.isPassword(this.passwordVal);
    },
    isPassword(password) {
      //replace(/\s*/g, '')
      // console.log(password)
      const emailRule = /^\w{6,20}$/;
      let isWrong = emailRule.test(password);
      if (password == "") {
        return "密码不能为空";
      } else {
        //let isWrong = isPassword(this.passwordVal)
        if (!isWrong) {
          return "密码为6-20字符，由英文大小写、数字组成";
        } else {
          return "";
        }
      }
    },
    reset() {
      let scope = this;
      resetPassword(this.iphoneVal, this.passwordVal, this.verCodeVal, function (info) {
        if (info.code == 10000) {
          scope.$parent.switchState("login");
        } else {
          getMapData(function () {
            scope.$Message["info"]({
              background: true,
              content: info.msg
            });
          });
        }
      });
    },
    onBlur() {
      let iponeRule = /^[1][3,4,5,6,7,8,9][0-9]{9}$/;
      if (!this.iphoneVal) {
        this.isShowError_null = true;
        this.isShowError_ipone = false;
      } else {
        if (!iponeRule.test(this.iphoneVal)) {
          this.isShowError_null = false;
          this.isShowError_ipone = true;
        } else {
          this.isShowError_null = false;
          this.isShowError_ipone = false;
        }
      }
    },
    getCode() {
      let scope = this;
      let time = 59;
      if (this.isPostCode && this.iphoneVal && !this.isShowError_null && !this.isShowError_ipone) {
        // 调取获取验证码接口
        getVerCode(this.iphoneVal, 2, function (info) {
          getMapData(function (messageMap) {
            scope.$Message["info"]({
              background: true,
              content: messageMap.get(info.code)
            });
          });
        });

        // 显示不同文案
        let timer = setInterval(function () {
          if (time == 0) {
            clearInterval(timer);
            scope.textVal = this.$t("common.getCode");
            scope.isPostCode = true;
          } else {
            scope.textVal = "还剩下" + time + "秒";
            time--;
            scope.isPostCode = false;
          }
        }, 1000);
      }
    }
  }
};