import Vue from "vue";
import VueI18n from "vue-i18n";
import zhCN from "./assets/js/locales/zh-CN";
import enUS from "./assets/js/locales/en-US";
Vue.use(VueI18n);
const messages = {
  "zh-CN": zhCN,
  "en-US": enUS
};
const i18n = new VueI18n({
  locale: "zh-CN",
  // 默认语言
  messages
});
export default i18n;