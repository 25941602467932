import _MessageBox2 from "element-ui/lib/theme-chalk/message-box.css";
import "element-ui/lib/theme-chalk/base.css";
import _MessageBox from "element-ui/lib/message-box";
import _Message from "view-design/src/components/message";
import _Switch from "view-design/src/components/switch";
import _Icon3 from "view-design/src/components/icon";
import _Spin from "view-design/src/components/spin";
import _Modal from "view-design/src/components/modal";
import _DropdownMenu from "view-design/src/components/dropdown-menu";
import _DropdownItem from "view-design/src/components/dropdown-item";
import _Dropdown from "view-design/src/components/dropdown";
import _Input from "view-design/src/components/input";
import _Button from "view-design/src/components/button";
import _ColorPicker from "view-design/src/components/color-picker";
import _Slider from "view-design/src/components/slider";
import _Tooltip from "view-design/src/components/tooltip";
import _Col from "view-design/src/components/col";
import _Row from "view-design/src/components/row";
import _Poptip from "view-design/src/components/poptip";
import _Split from "view-design/src/components/split";
import _Loading2 from "element-ui/lib/theme-chalk/loading.css";
import "element-ui/lib/theme-chalk/base.css";
import _Loading from "element-ui/lib/loading";
import _Icon2 from "element-ui/lib/theme-chalk/icon.css";
import "element-ui/lib/theme-chalk/base.css";
import _Icon from "element-ui/lib/icon";
import _Dialog2 from "element-ui/lib/theme-chalk/dialog.css";
import "element-ui/lib/theme-chalk/base.css";
import _Dialog from "element-ui/lib/dialog";
import _Option2 from "element-ui/lib/theme-chalk/option.css";
import "element-ui/lib/theme-chalk/base.css";
import _Option from "element-ui/lib/option";
import _Select2 from "element-ui/lib/theme-chalk/select.css";
import "element-ui/lib/theme-chalk/base.css";
import _Select from "element-ui/lib/select";
import Vue from "vue";
import App from "./App.vue";
import router from "./router/index";
import store from "./store";
import "view-design/dist/styles/iview.css";
import "element-ui/lib/theme-chalk/index.css";
// import ElementUI from 'element-ui';
// import 'element-ui/lib/theme-chalk/index.css';
// Vue.use(ElementUI);

//element-ui
Vue.use(_Select);
Vue.use(_Option);
Vue.use(_Dialog);
Vue.use(_Icon);
Vue.use(_Loading.directive);

//view-design
Vue.component("Split", _Split);
Vue.component("Poptip", _Poptip);
Vue.component("Row", _Row);
// eslint-disable-next-line vue/no-reserved-component-names
Vue.component("Col", _Col);
Vue.component("Tooltip", _Tooltip);
Vue.component("Slider", _Slider);
Vue.component("ColorPicker", _ColorPicker);
// eslint-disable-next-line vue/no-reserved-component-names
Vue.component("Button", _Button);
// eslint-disable-next-line vue/no-reserved-component-names
Vue.component("Input", _Input);
Vue.component("Dropdown", _Dropdown);
Vue.component("DropdownItem", _DropdownItem);
Vue.component("DropdownMenu", _DropdownMenu);
Vue.component("Modal", _Modal);
Vue.component("Spin", _Spin);
Vue.component("Icon", _Icon3);
Vue.component("i-switch", _Switch);
Vue.prototype.$Message = _Message;
Vue.prototype.$confirm = _MessageBox.confirm;
Vue.prototype.$loading = _Loading.service;

// import "./util/rem";
// view-design
// import ViewUI from "view-design";
// import "view-design/dist/styles/iview.css";
// Vue.use(ViewUI)

import "@/assets/css/common.css";
import "./assets/icons"; // icon
import * as filters from "./filters";
Object.keys(filters).forEach(key => Vue.filter(key, filters[key]));
import VueLazyload from "vue-lazyload";
Vue.use(VueLazyload);
Vue.config.productionTip = false;
Vue.prototype.bus = new Vue();
import i18n from "./i18n";
var vueDom = new Vue({
  router,
  store,
  render: h => h(App),
  i18n
}).$mount("#app");
export default vueDom;