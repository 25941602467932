var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', [_c('div', {
    staticClass: "tac content_bg"
  }, [_c('div', {
    staticClass: "pt65",
    staticStyle: {
      "width": "1334px",
      "margin": "0 auto"
    }
  }, [_vm.$i18n.locale === 'zh-CN' ? _c('p', {
    staticClass: "f28 fw600 col0B"
  }, [_vm._v("合作伙伴")]) : _vm._e(), _c('p', {
    staticClass: "content_2_iengTitle f28 fw600 col0B"
  }, [_vm._v("Cooperative Partner")]), _c('div', {
    staticClass: "partner_wrap1 flex fw_wrap ai_center"
  }, _vm._l(_vm.partnerList, function (item, index) {
    return _c('div', {
      key: index,
      class: ['partner_item', _vm.itemIndex == index ? 'partner_item_a' : ''],
      on: {
        "mouseover": function ($event) {
          return _vm.mouseoverFun(index);
        },
        "mouseout": function ($event) {
          _vm.itemIndex = null;
        }
      }
    }, [_c('img', {
      attrs: {
        "loading": "lazy",
        "src": require('../../../public/home/images/icon/logo' + (index + 1) + '.png'),
        "alt": ""
      }
    })]);
  }), 0)])])]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };