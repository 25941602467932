var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    on: {
      "keyup": function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.loginBut.apply(null, arguments);
      }
    }
  }, [_c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.isWxLogin,
      expression: "isWxLogin"
    }],
    ref: "login_container",
    attrs: {
      "id": "login_container"
    }
  }), _c('Button', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.isWxLogin,
      expression: "isWxLogin"
    }],
    staticClass: "button_account",
    attrs: {
      "type": "primary"
    },
    on: {
      "click": _vm.accountLoginClick
    }
  }, [_vm._v(_vm._s(_vm.$t("common.accountLogin")))]), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.isWxLogin,
      expression: "!isWxLogin"
    }],
    staticClass: "container_box"
  }, [_c('div', {
    staticClass: "title_iphone"
  }, [_vm._v(_vm._s(_vm.$t("common.enterPhoneNumber")))]), _c('div', {
    staticClass: "input_iphone"
  }, [_c('p', {
    staticClass: "valStyle"
  }, [_vm._v("+86    |")]), _c('Input', {
    staticClass: "input_1",
    attrs: {
      "placeholder": _vm.$t('common.mobilePhoneNumbersAre'),
      "maxlength": "11",
      "clearable": "",
      "autocomplete": "off"
    },
    on: {
      "on-blur": _vm.onBlur
    },
    model: {
      value: _vm.iphoneVal,
      callback: function ($$v) {
        _vm.iphoneVal = $$v;
      },
      expression: "iphoneVal"
    }
  })], 1), _vm.isShowError_iphone ? _c('div', {
    staticClass: "showError_1"
  }, [_vm._v(" " + _vm._s(_vm.$t("common.enterPhoneError")) + " ")]) : _vm._e(), _vm.isShowError_null ? _c('div', {
    staticClass: "showError_1"
  }, [_vm._v(" " + _vm._s(_vm.$t("common.enterPhoneNumber")) + " ")]) : _vm._e(), _c('div', {
    staticClass: "code_text"
  }, [_vm._v(_vm._s(_vm.$t("common.enterPassword")))]), _c('div', {
    staticClass: "code_forget",
    on: {
      "click": _vm.reset
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("common.forgetPassword")) + "? ")]), _c('div', {
    staticClass: "input_code"
  }, [_c('Input', {
    staticClass: "input_2",
    attrs: {
      "type": "password",
      "password": "",
      "autocomplete": "new-password",
      "maxlength": "20"
    },
    on: {
      "on-change": _vm.passwordChange
    },
    nativeOn: {
      "keyup": function ($event) {
        return _vm.btKeyUp.apply(null, arguments);
      },
      "keydown": function ($event) {
        return _vm.btKeyDown.apply(null, arguments);
      }
    },
    model: {
      value: _vm.passwordVal,
      callback: function ($$v) {
        _vm.passwordVal = $$v;
      },
      expression: "passwordVal"
    }
  }), _c('p', [_vm._v(_vm._s(_vm.passwordWrong))])], 1), _c('Button', {
    staticClass: "button_iphone",
    attrs: {
      "type": "primary"
    },
    on: {
      "click": _vm.loginBut
    }
  }, [_vm._v(_vm._s(_vm.$t("common.login")))]), _c('div', {
    staticClass: "line"
  }), _c('div', {
    staticClass: "otherLogin"
  }, [_vm._v(_vm._s(_vm.$t("common.thirdPartyLogin")))]), _c('Button', {
    staticClass: "button_wx",
    attrs: {
      "type": "primary"
    },
    on: {
      "click": _vm.wxLoginClick
    }
  }, [_vm._v(_vm._s(_vm.$t("common.wechatLogin")))]), _c('div', {
    staticClass: "icon_wx"
  }), _c('div', {
    staticClass: "footerEnter"
  }, [_c('p', {
    staticClass: "footer_1"
  }, [_vm._v(_vm._s(_vm.$t("common.noAccount")) + "？")]), _c('p', {
    staticClass: "footer_2",
    on: {
      "click": _vm.register
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("common.quickRegistration")) + " ")])])], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };