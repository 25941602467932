var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "bg"
  }, [_vm.isLogin ? _c('div', {
    ref: "loginStyle",
    staticClass: "loginStyle"
  }, [_c('div', {
    staticClass: "header"
  }, [_c('header-component')], 1), _c('div', {
    staticClass: "otherText"
  }, [_c('login-component', _vm._b({
    attrs: {
      "changeLoginStyle": _vm.changeLoginStyle,
      "changeReStyle": _vm.changeReStyle
    }
  }, 'login-component', _vm.$attrs, false))], 1)]) : _vm._e(), _vm.isRegister ? _c('div', {
    ref: "reStyle",
    staticClass: "reStyle"
  }, [_c('div', {
    staticClass: "header"
  }, [_c('header-component')], 1), _c('div', {
    staticClass: "otherText"
  }, [_c('register-component', _vm._b({
    attrs: {
      "otherModel": _vm.otherModel,
      "changeReStyle": _vm.changeReStyle,
      "changeLoginStyle": _vm.changeLoginStyle
    }
  }, 'register-component', _vm.$attrs, false))], 1)]) : _vm._e(), _vm.isReset ? _c('div', {
    ref: "resetStyle",
    staticClass: "resetStyle"
  }, [_c('div', {
    staticClass: "header"
  }, [_c('header-component')], 1), _c('div', {
    staticClass: "otherText"
  }, [_c('reset-component', _vm._b({
    attrs: {
      "changeLoginStyle": _vm.changeLoginStyle
    }
  }, 'reset-component', _vm.$attrs, false))], 1)]) : _vm._e()]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };