import { changeusername } from "../../assets/js/api/axiosApi";
import { getMapData } from "../../assets/js/api/messageMap";
export default {
  name: "ReviseName",
  data() {
    return {
      accountInfo: ""
    };
  },
  created() {
    // this.bus.$off("accountInfo");
    this.bus.$on("accountInfo", res => {
      this.accountInfo = res;
    });
  },
  mounted() {},
  methods: {
    disRN() {
      this.$refs.containerRN.style.display = "none";
    },
    saveName() {
      let scope = this;
      changeusername(this.accountInfo.username, this.accountInfo.id, function (info) {
        getMapData(function () {
          scope.$Message["info"]({
            background: true,
            content: info.msg
          });
        });
      });
      this.disRN();
    }
  }
};