var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    ref: "containerAccount",
    class: _vm.isDefaultTheme ? 'containerAccount bgStyleT' : 'containerAccount bgStyleF'
  }, [_c('div', {
    class: _vm.isDefaultTheme ? 'settingTitle titleColorT' : 'settingTitle titleColorF'
  }, [_vm._v(" 账号设置 ")]), _c('div', {
    staticClass: "xArea",
    on: {
      "click": _vm.disAccount
    }
  }, [_c('p', {
    staticClass: "xIcon"
  }, [_c('svg-icon', {
    attrs: {
      "icon-class": "close",
      "class-name": "f16"
    }
  })], 1)]), _c('div', {
    staticClass: "lineTop"
  }), _c('div', {
    staticClass: "img-head-div"
  }, [_c('div', {
    ref: "imgHead",
    staticClass: "imgHead"
  }, [_vm.$store.state.avatarUrl ? _c('img', {
    attrs: {
      "src": _vm.$store.state.avatarUrl,
      "alt": ""
    }
  }) : _c('img', {
    attrs: {
      "src": "/home/images/icon/header.png",
      "alt": ""
    }
  })]), _c('div', {
    staticClass: "editFont"
  }, [_vm._v("编辑")]), _c('input', {
    ref: "imgHeadFileUpload",
    attrs: {
      "id": "imgHeadFileUpload",
      "name": "imgHeadFileUpload",
      "type": "file",
      "value": "",
      "accept": ".webp,.jpeg,.jpg,.png"
    },
    on: {
      "change": function ($event) {
        return _vm.imgHeadFileChange();
      }
    }
  })]), _c('div', {
    staticClass: "publicFont_1 nameAccount"
  }, [_vm._v("名称")]), _c('div', {
    staticClass: "publicFont_2 netName"
  }, [_vm._v(_vm._s(_vm.accountInfo.username))]), _c('div', {
    staticClass: "publicFont_3 editName",
    on: {
      "click": _vm.editEven
    }
  }, [_vm._v("编辑")]), _c('div', {
    staticClass: "publicFont_1 passwordStyle"
  }, [_vm._v("密码")]), _c('div', {
    staticClass: "publicFont_2 pointStyle"
  }, [_vm._v("········")]), _c('div', {
    staticClass: "publicFont_3 changeCode",
    on: {
      "click": _vm.editCode
    }
  }, [_vm._v("修改密码")]), _c('div', {
    staticClass: "publicFont_1 phoneStyle"
  }, [_vm._v("手机号")]), _c('div', {
    staticClass: "publicFont_2 phoneCode"
  }, [_vm._v(_vm._s(_vm.phoneVal))]), _c('div', {
    staticClass: "publicFont_1 wxStyle"
  }, [_vm._v("微信")]), _c('div', {
    staticClass: "publicFont_2 wxState"
  }, [_vm._v("未绑定")]), _c('div', {
    staticClass: "secondLine"
  }), _c('div', {
    staticClass: "publicFont_1 accID"
  }, [_vm._v("账号")]), _c('div', {
    staticClass: "publicFont_2 accCode"
  }, [_vm._v(_vm._s(_vm.accountInfo.id))]), _c('div', {
    staticClass: "publicFont_1 accType"
  }, [_vm._v("账号类型")]), _c('div', {
    staticClass: "publicFont_2 realType"
  }, [_vm._v("个人免费版")])]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };