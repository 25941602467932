import "core-js/modules/web.dom-exception.stack.js";
import "../../assets/css/index.css";
import { register, getVerCode, wxLogin } from "../../assets/js/api/axiosApi";
import { getMapData } from "../../assets/js/api/messageMap";
export default {
  name: "Register",
  props: ["isModal", "changeReStyle", "changeLoginStyle", "otherModel"],
  data() {
    return {
      verCodeVal: null,
      iphoneVal: null,
      passwordVal: null,
      isShowError_ipone: false,
      isShowError_null: false,
      textVal: this.$t("common.getCode"),
      isPostCode: true,
      isWxLogin: false,
      passwordWrong: ""
    };
  },
  watch: {
    isModal() {
      if (!this.isModal) {
        this.verCodeVal = "";
        this.iphoneVal = "";
        this.passwordVal = "";
        this.isShowError_null = false;
        this.isShowError_ipone = false;
        this.passwordWrong = "";
      }
    }
  },
  mounted() {},
  methods: {
    bindingPhoneClick() {
      // let code =
      //     document.URL.split("?")[1].split("&")[0].split("=")[0] == "code"
      //         ? document.URL.split("?")[1].split("&")[0].split("=")[1]
      //         : "";
      let code;
      let url = document.URL;
      const regex = /code=([^&]+)/;
      const match = url.match(regex);
      if (match && match[1]) {
        code = match[1];
      }
      if (code != undefined) {
        wxLogin(code, this.iphoneVal, this.passwordVal, this.verCodeVal, res => {
          if (res.code != 10000) {
            this.$parent.switchState("bindingPhone");
            this.$Message.error(res.msg);
          } else {
            let vdata = res.data;
            //这个result == 200 是登录状态
            localStorage.setItem("result", 200);
            localStorage.setItem("email", vdata.user.email);
            localStorage.setItem("openid", vdata.user.openid);
            localStorage.setItem("nation", vdata.user.nation);
            localStorage.setItem("phone", vdata.user.mobile);
            localStorage.setItem("id", vdata.user.id);
            localStorage.setItem("name", vdata.user.username);
            localStorage.setItem("token", vdata.token);
            this.$store.commit("setAvatarUrl", vdata.user.avatar);
            this.$store.commit("SET_LOGINOUT", "");
            this.$store.commit("SET_LOGINMODAL", false);
          }
        });
      }
    },
    // 只能输入英文、数字
    btKeyDown(e) {
      e.target.value = e.target.value.replace(/\s*/g, "");
    },
    //限制输入特殊字符
    btKeyUp(e) {
      e.target.value = e.target.value.replace(/[`~!@#$%^&*()_\-+=<>?:"{}|,./;'\\[\]·~！@#￥%……&*（）——\-+={}|《》？：“”【】、；‘'，。、]/g, "");
    },
    passwordChange() {
      this.passwordVal = this.passwordVal.replace(/\s+/g, "");
      this.passwordWrong = this.isPassword(this.passwordVal);
    },
    accountLoginClick() {
      this.isWxLogin = false;
      this.changeReStyle(false);
    },
    wxLoginClick() {
      const cssCode = `
      .impowerBox .title {opacity: 0;}
      .impowerBox .info {opacity: 0;}
      `;
      const cssBase64 = btoa(cssCode);
      let href = window.location.href;
      let path = encodeURI(href);
      let appid = process.env.VUE_APP_APPID;
      let WxLogin = window.WxLogin;
      new WxLogin({
        self_redirect: false,
        id: "login_container",
        appid: appid,
        scope: "snsapi_login",
        redirect_uri: path,
        state: "",
        style: "",
        href: "data:text/css;base64," + cssBase64
      });
      this.changeReStyle(true);
      this.isWxLogin = true;
    },
    async login() {
      await this.$parent.switchState("login");
      this.changeLoginStyle(false);
    },
    onBlur() {
      let iponeRule = /^[1][3,4,5,6,7,8,9][0-9]{9}$/;
      if (!this.iphoneVal) {
        this.isShowError_null = true;
        this.isShowError_ipone = false;
      } else {
        if (!iponeRule.test(this.iphoneVal)) {
          this.isShowError_null = false;
          this.isShowError_ipone = true;
        } else {
          this.isShowError_null = false;
          this.isShowError_ipone = false;
        }
      }
    },
    registerBut() {
      let scope = this;
      register(this.iphoneVal, this.passwordVal, this.verCodeVal, function (info) {
        getMapData(function () {
          scope.$Message["info"]({
            background: true,
            content: info.msg
          });
          // 注册成功的状态
          if (info.code == 10000) {
            // 页面跳转登录页
            scope.$parent.switchState("login");
          }
        });
      });
    },
    isPassword(password) {
      //replace(/\s*/g, '')
      // console.log(password)
      const emailRule = /^\w{6,20}$/;
      let isWrong = emailRule.test(password);
      if (password == "") {
        return "密码不能为空";
      } else {
        //let isWrong = isPassword(this.passwordVal)
        if (!isWrong) {
          return "密码为6-20字符，由英文大小写、数字组成";
        } else {
          return "";
        }
      }
    },
    getCode() {
      let scope = this;
      let time = 59;
      if (this.isPostCode && this.iphoneVal && !this.isShowError_null && !this.isShowError_ipone) {
        // 调取获取验证码接口
        getVerCode(this.iphoneVal, 1, function (info) {
          getMapData(function () {
            scope.$Message["info"]({
              background: true,
              content: info.msg
            });
          });
        });

        // 显示不同文案
        let timer = setInterval(function () {
          if (time == 0) {
            clearInterval(timer);
            scope.textVal = this.$t("common.getCode");
            scope.isPostCode = true;
          } else {
            scope.textVal = "还剩下" + time + "秒";
            time--;
            scope.isPostCode = false;
          }
        }, 1000);
      }
    }
  }
};