var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "header"
  }, [_c('div', {
    staticClass: "icon-colse pr10 pt10"
  }, [_c('svg-icon', {
    attrs: {
      "icon-class": "close",
      "class-name": "col63 f18 pointer"
    },
    on: {
      "click": _vm.closeModal
    }
  })], 1), _c('div', {
    staticClass: "pic"
  }), _c('div', {
    staticClass: "text"
  }, [_vm._v("为世界带来沉浸感好声音")])]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };