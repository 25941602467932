var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "footer_wrap"
  }, [_c('div', {
    staticClass: "footer_content flex"
  }, [_c('div', {
    staticClass: "footer_logo"
  }, [_vm._m(0), _c('p', {
    staticClass: "f14 fw400 col34 height24 tal mt34 text-left-warp"
  }, [_vm._v(" " + _vm._s(_vm.$t("footerNav.contactUsDescription")) + " ")]), _c('div', {
    staticClass: "footer_logo_btn br4 tac f14 fw500 colfff pointer",
    on: {
      "click": function ($event) {
        return _vm.handleContact();
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("footerNav.contactUs")) + " ")])]), _c('div', {
    staticClass: "flex1 tal marginl175"
  }, [_c('p', {
    staticClass: "mt32 f14 fw400 col34 pointer",
    on: {
      "click": _vm.DownloadWinPlayer
    }
  }), _c('p', {
    staticClass: "mt32 f14 fw400 col34 pointer",
    on: {
      "click": _vm.DownloadMacPlayer
    }
  })]), _c('div', {
    staticClass: "flex1 tal"
  }, [_c('p', {
    staticClass: "f16 fw500 col0B"
  }, [_vm._v(_vm._s(_vm.$t("footerNav.introduction")))]), _c('p', {
    staticClass: "mt32 f14 fw400 col34 mb24 pointer",
    on: {
      "click": function ($event) {
        return _vm.handleAboutOur();
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("footerNav.aboutUs")) + " ")]), _c('p', {
    staticClass: "f14 fw400 col34 mb24 pointer",
    on: {
      "click": function ($event) {
        return _vm.handleAgreement(1);
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("footerNav.servicePolicy")) + " ")]), _c('p', {
    staticClass: "f14 fw400 col34 mb24 pointer",
    on: {
      "click": function ($event) {
        return _vm.handleAgreement(2);
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("footerNav.privacyPolicy")) + " ")]), _c('p', {
    staticClass: "f14 fw400 col34 mb24 pointer",
    on: {
      "click": function ($event) {
        return _vm.handleSynopsis();
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("footerNav.companyProfile")) + " ")])]), _c('div', {
    staticClass: "flex1 tal"
  }, [_c('p', {
    staticClass: "f16 fw500 col0B"
  }, [_vm._v(_vm._s(_vm.$t("footerNav.followUs")))]), _c('div', {
    staticClass: "mt32 mb24"
  }, [_c('Poptip', {
    attrs: {
      "placement": "top"
    },
    scopedSlots: _vm._u([{
      key: "content",
      fn: function () {
        return [_c('follow', {
          attrs: {
            "title": _vm.$t('footerNav.wechatAccount'),
            "tipsTitle": _vm.$t('footerNav.wechat'),
            "type": 1
          }
        })];
      },
      proxy: true
    }])
  }, [_c('p', {
    staticClass: "f14 fw400 col34 pointer"
  }, [_vm._v(" " + _vm._s(_vm.$t("footerNav.wechatAccount")) + " ")])])], 1), _c('div', {
    staticClass: "mb24"
  }, [_c('p', {
    staticClass: "f14 fw400 col34 pointer",
    on: {
      "click": function ($event) {
        return _vm.handleAgreement(3);
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("footerNav.weiboAccount")) + " ")])])]), _c('div', {
    staticClass: "flex1 tal"
  }, [_c('p', {
    staticClass: "f16 fw500 col0B"
  }, [_vm._v(_vm._s(_vm.$t("footerNav.contactUs")))]), _c('p', {
    staticClass: "mt32 mb24 f14 fw400"
  }, [_c('a', {
    staticClass: "col34 a_email",
    attrs: {
      "href": "mailto:bd@wavarts.com"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("footerNav.businessCooperation")) + "：bd@wavarts.com ")])]), _c('div', {
    staticClass: "mb24"
  }, [_c('Poptip', {
    attrs: {
      "placement": "top"
    },
    scopedSlots: _vm._u([{
      key: "content",
      fn: function () {
        return [_c('follow', {
          attrs: {
            "title": _vm.$t('footerNav.businessConsulting'),
            "tipsTitle": _vm.$t('footerNav.wechat'),
            "type": 4
          }
        })];
      },
      proxy: true
    }])
  }, [_c('p', {
    staticClass: "f14 fw400 col34 pointer"
  }, [_vm._v(" " + _vm._s(_vm.$t("footerNav.businessConsulting")) + " ")])])], 1), _c('Poptip', {
    attrs: {
      "placement": "top"
    },
    scopedSlots: _vm._u([{
      key: "content",
      fn: function () {
        return [_c('follow', {
          attrs: {
            "title": _vm.$t('footerNav.communityGroup'),
            "tipsTitle": _vm.$t('footerNav.wechat'),
            "type": 2
          }
        })];
      },
      proxy: true
    }])
  }, [_c('p', {
    staticClass: "mb24 f14 fw400 col34 pointer"
  }, [_vm._v(" " + _vm._s(_vm.$t("footerNav.communityGroup")) + " ")])]), _c('div', {
    staticClass: "mb24"
  }, [_c('Poptip', {
    attrs: {
      "placement": "top"
    },
    scopedSlots: _vm._u([{
      key: "content",
      fn: function () {
        return [_c('follow', {
          attrs: {
            "title": _vm.$t('footerNav.feedback'),
            "tipsTitle": _vm.$t('footerNav.wechat'),
            "type": 3
          }
        })];
      },
      proxy: true
    }])
  }, [_c('p', {
    staticClass: "f14 fw400 col34 pointer"
  }, [_vm._v(" " + _vm._s(_vm.$t("footerNav.feedback")) + " ")])])], 1)], 1)]), _c('div', {
    staticClass: "f14 fw400 col34 mt20 flex jc_end",
    staticStyle: {
      "width": "1301px",
      "margin": "20px auto 110px",
      "color": "#939393"
    }
  }, [_c('p', {
    staticClass: "mr20"
  }, [_vm._v("2022-2032 © All Right Reserved by WANOS")]), _c('p', {
    staticClass: "mr20"
  }, [_vm._v("增值电信业务经营许可证: 京B2-20224012")]), _c('p', {
    staticClass: "mr20"
  }, [_vm._v("网络文化经营许可证: 京网文 (2023) 3863-118 号")]), _c('a', {
    staticClass: "mr20",
    staticStyle: {
      "color": "#939393"
    },
    attrs: {
      "href": "https://beian.miit.gov.cn",
      "target": "_blank"
    }
  }, [_vm._v(_vm._s(_vm.recodeCode))]), _c('div', [_c('a', {
    staticStyle: {
      "display": "inline-block",
      "text-decoration": "none",
      "height": "20px",
      "line-height": "20px"
    },
    attrs: {
      "target": "_blank",
      "href": _vm.href_C
    }
  }, [_c('div', {
    staticClass: "chinaIconStyle"
  }), _c('p', {
    staticStyle: {
      "float": "left",
      "height": "20px",
      "line-height": "20px",
      "margin": "0px 0px 0px 5px",
      "color": "#939393"
    }
  }, [_vm._v(" 京公网安备 11010702002524号 ")])])])]), _c('contact-us', {
    ref: "child"
  })], 1);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('p', {
    staticClass: "tal"
  }, [_c('img', {
    staticClass: "footer_logo_img",
    attrs: {
      "src": "/home/images/icon/logo.svg",
      "alt": ""
    }
  })]);
}];
render._withStripped = true;
export { render, staticRenderFns };