var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    class: _vm.isMobile ? 'mobileStyle' : 'code_wrap br4 pl20 pr20'
  }, [_c('p', {
    class: _vm.isMobile ? 'mobileTitle' : 'code_title f12 fw400 col34 tal'
  }, [_vm._v(" " + _vm._s(_vm.title) + " ")]), _c('div', {
    staticClass: "code_img br4"
  }, [_c('img', {
    staticClass: "br4",
    attrs: {
      "src": _vm.codeUrl,
      "alt": ""
    }
  })]), _c('div', {
    class: _vm.isMobile ? 'mobileText' : 'f12 fw400 code_tips tac col5F text-center-warp'
  }, [_vm._v(" " + _vm._s(_vm.$t("common.open") + "「" + _vm.tipsTitle + "APP」" + _vm.$t("footerNav.scanQRCode")) + " "), _c('br'), _vm._v(" " + _vm._s(_vm.type == 3 ? _vm.$t("footerNav.feedbackQuestions") : _vm.type == 2 ? _vm.$t("footerNav.communicationGroup") : _vm.type == 1 ? _vm.$t("footerNav.followWechatOfficialAccount") : _vm.type == 4 ? "" : _vm.$t("footerNav.shareFriends")) + " ")])]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };