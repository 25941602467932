var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('Modal', {
    attrs: {
      "width": "460"
    },
    scopedSlots: _vm._u([{
      key: "header",
      fn: function () {
        return [_c('div', {
          staticClass: "modal_header tac"
        }, [_c('div', [_c('img', {
          attrs: {
            "src": "/home/images/icon/LOGO_S.svg",
            "alt": ""
          }
        })]), _c('div', {
          staticClass: "modal_header_title f12 fw400"
        }, [_vm._v("为世界带来沉浸感好声音")])])];
      },
      proxy: true
    }, {
      key: "footer",
      fn: function () {
        return [_c('div', {
          staticClass: "f12 fw500 pt20 pb20 pr20"
        }, [_c('span', {
          staticClass: "modal_btn modal_btn_cancel col1A tac br4 pointer",
          on: {
            "click": function ($event) {
              return _vm.handleCancel();
            }
          }
        }, [_vm._v(_vm._s(_vm.$t("common.cancel")))]), _c('span', {
          staticClass: "modal_btn modal_btn_confirm colfff tac br4 ml8 pointer",
          on: {
            "click": function ($event) {
              return _vm.handleConfirm();
            }
          }
        }, [_vm._v(_vm._s(_vm.$t("common.confirm")))])])];
      },
      proxy: true
    }]),
    model: {
      value: _vm.isModal,
      callback: function ($$v) {
        _vm.isModal = $$v;
      },
      expression: "isModal"
    }
  }, [_c('div', {
    staticClass: "modal_content tal"
  }, [_c('p', {
    staticClass: "modal_content_title f12 fw500 col63"
  }, [_vm._v(" " + _vm._s(_vm.$t("common.enterPhoneNumber")) + " ")]), _c('div', {
    staticClass: "input_iphone mt8 flex"
  }, [_c('div', {
    staticClass: "valStyle ml20"
  }, [_vm._v("+86    |")]), _c('div', {
    staticClass: "flex1 ml10"
  }, [_c('Input', {
    staticClass: "input_1",
    attrs: {
      "maxlength": "11",
      "placeholder": _vm.$t('common.mobilePhoneNumbersAre'),
      "clearable": "",
      "border": false,
      "autocomplete": "off"
    },
    model: {
      value: _vm.formValidate.mobile,
      callback: function ($$v) {
        _vm.$set(_vm.formValidate, "mobile", $$v);
      },
      expression: "formValidate.mobile"
    }
  })], 1)]), _c('div', {
    staticClass: "err_txt"
  }, [_vm._v(_vm._s(_vm.isPhone))]), _c('p', {
    staticClass: "modal_content_title f12 fw500 col63"
  }, [_vm._v("E-mail")]), _c('div', {
    staticClass: "input_iphone mt8 flex"
  }, [_c('Input', {
    staticClass: "input_1",
    attrs: {
      "clearable": "",
      "border": false,
      "autocomplete": "off"
    },
    model: {
      value: _vm.formValidate.email,
      callback: function ($$v) {
        _vm.$set(_vm.formValidate, "email", $$v);
      },
      expression: "formValidate.email"
    }
  })], 1), _c('div', {
    staticClass: "err_txt"
  }, [_vm._v(_vm._s(_vm.isEmail))]), _c('p', {
    staticClass: "modal_content_title f12 fw500 col63"
  }, [_vm._v(" " + _vm._s(_vm.$t("common.needs")) + " ")]), _c('div', {
    staticClass: "mt8 flex"
  }, [_c('textarea', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.formValidate.content,
      expression: "formValidate.content"
    }],
    staticClass: "inputTextarea",
    attrs: {
      "type": "",
      "autocomplete": "off"
    },
    domProps: {
      "value": _vm.formValidate.content
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.formValidate, "content", $event.target.value);
      }
    }
  })]), _c('p', {
    staticClass: "f12 fw400 mt8 modal_content_tips"
  }, [_vm._v(" " + _vm._s(_vm.$t("common.needsDescription")) + " ")])])]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };