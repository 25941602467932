var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    ref: "conRUS",
    staticClass: "conRUS"
  }, [_c('div', {
    staticClass: "personPos"
  }, [_c('div', {
    staticClass: "headIcon"
  }), !_vm.isShowHome ? _c('div', {
    staticClass: "movPosition"
  }, [_c('p', {
    staticClass: "movIcon"
  })]) : _vm._e(), _c('div', {
    class: _vm.isShowHome ? 'listenPosition_T' : 'listenPosition_F'
  }, [_c('p', {
    staticClass: "listenIcon"
  })]), _c('div', {
    class: _vm.isShowHome ? 'settingPosition_T' : 'settingPosition_F'
  }, [_c('p', {
    staticClass: "settingIcon"
  })]), !_vm.isShowHome ? _c('div', {
    staticClass: "shareButt"
  }, [_vm._v("分享")]) : _vm._e()]), _c('div', {
    staticClass: "horn"
  }, [_c('p', {
    ref: "icon_7",
    staticClass: "icon_7"
  })]), _c('div', {
    staticClass: "personalStyle"
  }, [_c('account-component', {
    ref: "accountStyle"
  }), _c('reviseName-component', {
    ref: "reviseName"
  }), _c('reviseCode-component', {
    ref: "reviseCode",
    attrs: {
      "resetPassword": _vm.resetPassword
    }
  }), _c('settingCode-component', {
    ref: "settingCode"
  })], 1)]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };