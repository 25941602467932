import Account from "@/components/personal/Account";
import ReviseName from "@/components/personal/ReviseName";
import ReviseCode from "@/components/personal/ReviseCode";
import SettingCode from "@/components/personal/SettingCode";
export default {
  name: "RightUpShow",
  props: ["avatar"],
  data() {
    return {
      isShowHome: true,
      resetPassword: false
    };
  },
  components: {
    "account-component": Account,
    "reviseName-component": ReviseName,
    "reviseCode-component": ReviseCode,
    "settingCode-component": SettingCode
  },
  mounted() {},
  methods: {
    // 个人中心的一些操作
    // 展示修改弹窗
    showReviseName() {
      this.$refs.reviseName.$refs.containerRN.style.display = "block";
    },
    // 展示修改密码弹窗
    showReviseCode() {
      this.resetPassword = !this.resetPassword;
      this.$refs.reviseCode.$refs.containerCode.style.display = "block";
    },
    // 展示设置密码弹窗
    showSettingCode() {
      this.$refs.settingCode.$refs.containerSetting.style.display = "block";
    }
  },
  watch: {},
  computed: {}
};