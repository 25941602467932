import "core-js/modules/es.array.push.js";
import { changePassword } from "../../assets/js/api/axiosApi";
import { getMapData } from "../../assets/js/api/messageMap";
import { mapState } from "vuex";
export default {
  name: "ReviseCode",
  props: ["resetPassword"],
  data() {
    return {
      curPasswordVal: "",
      newPasswordVal: "",
      surePasswordVal: ""
    };
  },
  computed: {
    ...mapState({
      isLoginOut: state => state.isLoginOut
    }),
    realPassWord() {
      if (this.newPasswordVal == this.surePasswordVal) {
        return this.newPasswordVal;
      } else {
        return "";
      }
    }
  },
  mounted() {},
  methods: {
    displayCode() {
      this.$refs.containerCode.style.display = "none";
    },
    forgetCode() {
      this.$refs.containerCode.style.display = "none";
      this.$parent.showSettingCode();
    },
    clearPassword() {
      this.curPasswordVal = "";
      this.newPasswordVal = "";
      this.surePasswordVal = "";
    },
    sureVal() {
      let scope = this;
      if (this.realPassWord) {
        changePassword(this.curPasswordVal, this.realPassWord, function (info) {
          if (info.code == 10000) {
            getMapData(function () {
              // 登录时弹窗提醒
              scope.$Message["info"]({
                background: true,
                content: info.msg
              });
            });
            scope.$refs.containerCode.style.display = "none";
            localStorage.clear(); //清除页面所有localStorage，退出登录
            let x = isNaN(scope.isLoginOut) ? 0 : scope.isLoginOut;
            scope.$store.commit("SET_LOGINOUT", x + 1);
            scope.$store.commit("SET_LOGINMODAL", true);
            if (scope.$route.name == "PersonalCenter") {
              scope.$router.push({
                name: "ImmersiveAudio"
              });
            }
          } else {
            getMapData(function () {
              // 登录时弹窗提醒
              scope.$Message["info"]({
                background: true,
                content: info.msg
              });
            });
          }
        });
      } else {
        this.$Message["info"]({
          background: true,
          content: "两次密码输入不一致"
        });
      }
    }
  },
  watch: {
    resetPassword() {
      this.clearPassword();
    }
  }
};