import _Message from "view-design/src/components/message";
import axios from "axios";
// import ViewUI from "view-design";

//import serverType from "./axiosApi";

import store from "../../../store/index";
// import { getToken } from '@/utils/auth'

var vbaseURL;
var serverType = process.env.VUE_APP_SERVER_TYPE;
// serverType = 5
if (serverType == 1) {
  vbaseURL = process.env.VUE_APP_BASE_API;
} else if (serverType == 3) {
  vbaseURL = "http://192.168.1.224:8123/";
} else if (serverType == 5) {
  vbaseURL = "https://beer-api.wanos.vip/";
} else if (serverType == 6) {
  vbaseURL = "https://api-pre.wanos.cc/";
} else {
  vbaseURL = "https://air-api.wanos.vip/";
}

// 创建axios实例
const publicAxios = axios.create({
  baseURL: vbaseURL,
  // api的base_url
  // baseURL: 'http://101.42.108.109:8125/', // api的base_url
  timeout: 15000 // 请求超时时间
});

// request拦截器
publicAxios.interceptors.request.use(config => {
  if (localStorage.getItem("token")) {
    config.headers.token = localStorage.getItem("token");
    // if (config.method === 'post' ) {
    //   config.headers['Content-Type'] =  'application/x-www-form-urlencoded;charset=utf-8'

    // }
  }
  return config;
}, error => {
  // Do something with request error
  Promise.reject(error);
});

// respone拦截器
publicAxios.interceptors.response.use(response => {
  /**
   * code为非200是抛错 可结合自己业务进行修改
   */
  const res = response.data;

  // if (res.code !== 0) {
  //   Message({
  //     message: res.message,
  //     type: 'error',
  //     duration: 3 * 1000
  //   })

  // 401:未登录;
  if (res.code == 20000) {
    _Message["error"]({
      background: true,
      content: res.msg
    });
    // localStorage.clear();
    // location.href = '/Login';
    store.dispatch("FedLogOut");
    return Promise.reject("error");
  } else {
    return response.data;
  }
}, error => {
  this.Message({
    message: error.message,
    type: "error",
    duration: 3 * 1000
  });
  return Promise.reject(error);
});
export default publicAxios;