/**
 * @param {string} path
 * @returns {Boolean}
 */
export function isExternal(path) {
  return /^(https?:|mailto:|tel:)/.test(path);
}
export function isPhone(phone) {
  const iponeRule = /^[1][3,4,5,6,7,8,9][0-9]{9}$/;
  return iponeRule.test(phone);
}
export function isEmail(phone) {
  const emailRule = /^[\w\-.]+@[a-z0-9]+(-[a-z0-9]+)?(\.[a-z0-9]+(-[a-z0-9]+)?)*\.[a-z]{2,4}$/i;
  return emailRule.test(phone);
}