import "core-js/modules/es.array.push.js";
import { mapState } from "vuex";
import { isImgUrlValid } from "../../util/validate";
import { verificationToken } from "@/assets/js/api/community";
import Personal from "../personal/RightUpShow";
// import Login from './Login/Login'
import Login from "@/views/Login/Login";
export default {
  name: "Header-Component",
  components: {
    Personal,
    Login
  },
  props: ["isWxLogin", "getData", "activityProjectListClear"],
  data() {
    return {
      languageOptions: [{
        value: "en-US",
        label: "English"
      }, {
        value: "zh-CN",
        label: "中文"
      }],
      languageValue: "zh-CN",
      modal2: false,
      navList: [{
        text: "immersiveAudio",
        name: "ImmersiveAudio"
      }, {
        text: "interactiveAudio",
        name: "InteractiveAudio"
      }, {
        text: "creator",
        name: "Creator"
      }, {
        text: "resources",
        name: "Resources"
      }, {
        text: "aboutOur",
        name: "AboutOur"
      }],
      // type: this.headerIndex,
      type: 0,
      isLogin: false,
      seachStr: null,
      keyword: null,
      isModal: false
    };
  },
  computed: {
    ...mapState({
      navClass: state => state.navClass,
      loginModal: state => state.loginModal,
      isLoginOut: state => state.isLoginOut
    })
  },
  watch: {
    isLogin() {
      this.$store.commit("SET_USERID", localStorage.getItem("id"));
      //如果在活动页面去请求活动list
      if (this.type == 8) {
        this.activityProjectListClear();
        this.getData();
      }
    },
    isWxLogin(val) {
      this.isLogin = val;
    },
    navClass() {
      this.type = sessionStorage.getItem("navType") ? sessionStorage.getItem("navType") : 0;
    },
    loginModal() {
      this.isModal = this.loginModal;
      if (!this.loginModal) {
        let result = localStorage.getItem("result");
        if (result == 200) {
          this.isLogin = true;
        } else {
          this.isLogin = false;
        }
      }
    },
    isLoginOut() {
      let result = localStorage.getItem("result");
      if (result == 200) {
        this.isLogin = true;
      } else {
        this.isLogin = false;
      }
    },
    $route() {
      this.goBack();
    }
  },
  beforeCreate() {},
  created() {
    let result = localStorage.getItem("result");
    if (result == 200) {
      this.isLogin = true;
    } else {
      this.isLogin = false;
    }
    this.type = sessionStorage.getItem("navType") ? sessionStorage.getItem("navType") : this.$router.query && this.$router.query.headerIndex ? this.$router.query.headerIndex : 0;
  },
  beforeMount() {},
  mounted() {
    let self = this;
    // 绑定监听事件
    window.addEventListener("keydown", this.keyDown);
    // 监听是否登录
    window.addEventListener("storage", e => {
      if (!e.key) {
        self.isLogin = false;
      } else if (e.key == "result") {
        if (e.newValue == 200) {
          self.isLogin = true;
        } else {
          self.isLogin = false;
        }
      }
    });
    this.goBack();
  },
  beforeDestroy() {
    window.removeEventListener("keydown", this.keyDown, false);
  },
  //方法集合
  methods: {
    languageOptionsChange(lang) {
      this.languageValue = lang;
      this.$i18n.locale = this.languageValue;
    },
    onChange(data) {
      if (!data) {
        this.$store.commit("SET_LOGINMODAL", false);
      }
    },
    isImg(imgUrl) {
      let img = "";
      isImgUrlValid(imgUrl).then(res => {
        if (res && res.type == "load") {
          img = imgUrl;
        } else {
          img = require("../../../public/home/images/icon/header.png");
        }
      });
      return img;
    },
    // 点浏览器返回，导航样式
    goBack() {
      let self = this;
      switch (self.$route.path) {
        case "/AboutOur":
          sessionStorage.setItem("navType", 4);
          self.type = 4;
          break;
        case "/home/ImmersiveAudio":
          sessionStorage.setItem("navType", 0);
          self.type = 0;
          break;
        case "/home/InteractiveAudio":
          sessionStorage.setItem("navType", 1);
          self.type = 1;
          break;
        case "/home/Creator":
          sessionStorage.setItem("navType", 2);
          self.type = 2;
          break;
        case "/home/Resources":
          sessionStorage.setItem("navType", 3);
          self.type = 3;
          break;
        case "/PersonalCenter":
          sessionStorage.setItem("navType", null);
          self.type = null;
          break;
      }
      let navType = self.type == 2 || self.type == 0 || self.type == 3 || self.type == 4 ? 2 : 1;
      sessionStorage.setItem("navClass", navType); // 导航样式
      this.$store.dispatch("SET_NAVCLASS_ACTIVE", navType);
      this.$forceUpdate();
    },
    // 切换头部导航
    handleClick(index) {
      this.type = index;
      this.navType = index == 2 || index == 0 || index == 3 || index == 4 || index == 8 ? 2 : 1;
      sessionStorage.setItem("navClass", this.navType); // 导航样式
      sessionStorage.setItem("navType", index); // 导航下标
      this.$store.dispatch("SET_NAVCLASS_ACTIVE", this.navType);
      this.$store.commit("setFirstLoad", false);
      if (index == 5) {
        let userId = localStorage.getItem("id");
        this.$store.commit("SET_USERID", userId);
        this.$router.push({
          name: "PersonalCenter",
          query: {
            id: userId
          }
        });
      } else {
        if (this.navList[index]) {
          this.$router.push({
            name: this.navList[index].name
          });
        }
      }
      this.$forceUpdate();
    },
    // 点击登陆按钮
    handleLogin() {
      this.$store.commit("SET_LOGINMODAL", true);
    },
    // 退出登录
    signOut() {
      let time;
      if (localStorage.getItem("popuptime")) {
        time = localStorage.getItem("popuptime");
      }
      localStorage.clear();
      if (time) {
        localStorage.setItem("popuptime", time);
      }
      if (this.$route.name == "PersonalCenter") {
        this.$router.push({
          name: "ImmersiveAudio"
        });
      }
      let x = isNaN(this.isLoginOut) ? 0 : this.isLoginOut;
      this.$store.commit("SET_LOGINOUT", x + 1);
      window.location.href = window.location.href.split("?")[0];
      //  关闭所有弹框
      let doc = this.$children[1].accountStyle ? this.$children[1] : this.$children[0];
      doc.$refs.accountStyle.$refs.containerAccount.style.display = "none";
      doc.$refs.reviseCode.$refs.containerCode.style.display = "none";
      doc.$refs.reviseName.$refs.containerRN.style.display = "none";
      doc.$refs.settingCode.$refs.containerSetting.style.display = "none";
    },
    getUserInfo(val) {
      this.userInfo = val;
    },
    // 前往工作室
    handleOpenEdit() {
      // 验证token
      // let token = localStorage.getItem("token");
      let url = process.env.VUE_APP_BASE_FREE;
      this.$router.push({
        params: {
          url: process.env.VUE_APP_BASE_FREE
        }
      });
      verificationToken().then(res => {
        if (res.code == 10000) {
          window.open(url, "_blank");
        }
      });
    },
    // 打开账号设置弹框
    handleAccountShow() {
      let doc = this.$children[1].$refs.accountStyle ? this.$children[1].$refs.accountStyle : this.$children[0].$refs.accountStyle;
      doc.$refs.containerAccount.style.display = "block";
      // doc.getAccountInfo()
    }
  }
};