// 接口管理
import axios from "axios";
import "../proto/protobuf";

// 接口basicUrl设定
// var basicUrl = "http://101.42.108.109:8125/";

//import serverType from "./axiosApi";
var serverType = process.env.VUE_APP_SERVER_TYPE;
var basicUrl;
// serverType = 5;
let exportUrl;
if (serverType == 1) {
  basicUrl = process.env.VUE_APP_BASE_API;
  exportUrl = "https://api.wanos.cc:9093/";
} else if (serverType == 3) {
  basicUrl = "http://192.168.1.224:8123/";
  exportUrl = "http://air-api.wanos.vip:9093/";
} else if (serverType == 5) {
  basicUrl = "https://beer-api.wanos.vip/";
  exportUrl = "http://beer-api.wanos.vip:9093/";
} else if (serverType == 6) {
  basicUrl = "https://api-pre.wanos.cc/";
  exportUrl = "http://api-pre.wanos.cc:9093/";
} else {
  basicUrl = "https://air-api.wanos.vip/";
  exportUrl = "https://air-api.wanos.vip:9093/";
}

// axios公共方法
var publicAxios = function (message, api_c2g, operatype, callback) {
  let serverUrl = basicUrl + api_c2g;
  axios.interceptors.request.use(config => {
    if (localStorage.getItem("token")) {
      config.headers.token = localStorage.getItem("token");
    }
    return config;
  }, error => {
    return Promise.reject(error);
  });
  if (operatype == "get") {
    axios.get(serverUrl, message).then(function (res) {
      callback(res);
    }).catch(function (error) {
      console.log(error);
    });
  } else if (operatype == "put") {
    axios.put(serverUrl, message).then(function (res) {
      callback(res);
    }).catch(function (error) {
      console.log(error);
    });
  } else if (operatype == "post") {
    axios.post(serverUrl, message).then(function (res) {
      callback(res);
    }).catch(function (error) {
      console.log(error);
    });
  }
};
const exportAxios = function (message, api_c2g, operatype, callback) {
  let serverUrl = exportUrl + api_c2g;
  axios.interceptors.request.use(config => {
    if (localStorage.getItem("token")) {
      config.headers.token = localStorage.getItem("token");
    }
    return config;
  }, error => {
    return Promise.reject(error);
  });
  if (operatype == "get") {
    axios.get(serverUrl, {
      params: message
    }).then(function (res) {
      callback(res);
    }).catch(function (error) {
      console.log(error);
    });
  } else if (operatype == "put") {
    axios.put(serverUrl, message).then(function (res) {
      callback(res);
    }).catch(function (error) {
      console.log(error);
    });
  } else if (operatype == "post") {
    axios.post(serverUrl, message).then(function (res) {
      callback(res);
    }).catch(function (error) {
      console.log(error);
    });
  }
};
export { publicAxios, exportAxios };