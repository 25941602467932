import Vue from "vue";
import VueRouter from "vue-router";
Vue.use(VueRouter);
import home from "../views/home.vue";
const routes = [{
  path: "/",
  redirect: "/home/ImmersiveAudio"
}, {
  path: "/home",
  name: "home",
  component: home,
  children: [
  // {
  //   path: "",
  //   redirect: '/home/ImmersiveAudio'
  // },
  {
    path: "ImmersiveAudio",
    name: "ImmersiveAudio",
    //沉浸式音频
    component: () => import("../views/OfficialVebsite/ImmersiveAudio.vue")
  }, {
    path: "InteractiveAudio",
    name: "InteractiveAudio",
    //沉浸式音频
    component: () => import("../views/OfficialVebsite/InteractiveAudio.vue")
  }, {
    path: "Creator",
    name: "Creator",
    //创作者
    component: () => import("../views/OfficialVebsite/Creator.vue")
  }, {
    path: "Resources",
    name: "Resources",
    //资源社区
    component: () => import("../views/Community/Resources.vue")
  }, {
    path: "PersonalCenter",
    name: "PersonalCenter",
    //个人中心
    component: () => import("../views/Community/PersonalCenter.vue")
  }, {
    path: "AboutOur",
    name: "AboutOur",
    //关于我们
    component: () => import("../views/Other/AboutOur")
  }]
}, {
  path: "/home/Play",
  name: "Play",
  // component: () => import("../views/Community/VideoPlay.vue"),
  component: () => import("../views/Community/Play.vue")
}, {
  path: "/home/Login",
  name: "Login",
  component: () => import("../views/Login/Login.vue")
}, {
  path: "/home/Share",
  name: "Share",
  //移动端
  component: () => import("../views/Community/Share.vue")
}, {
  path: "/home/Agreement",
  name: "Agreement",
  //协议
  component: () => import("../views/Other/Agreement")
}, {
  path: "/home/Synopsis",
  name: "Synopsis",
  //公司简介
  component: () => import("../views/Other/Synopsis")
}, {
  path: "/home/Activity",
  name: "Activity",
  //新年活动
  component: () => import("../views/Other/Activity")
},
// 下方为手机端路由
{
  path: "/home/mobile",
  name: "MHeader",
  //页面头部，导航用
  component: () => import("../viewsMobile/MHeader.vue"),
  children: [
  // { path: '/', redirect: '/home/mobile/immerse'},
  {
    path: "/home/mobile/immerse",
    name: "MImmerse",
    //沉浸式音频
    component: () => import("../viewsMobile/MImmerse.vue")
  }, {
    path: "/home/mobile/interaction",
    name: "MInteraction",
    //互动式音频
    component: () => import("../viewsMobile/MInteraction.vue")
  }, {
    path: "/home/mobile/creator",
    name: "MCreator",
    //创作者
    component: () => import("../viewsMobile/MCreator.vue")
  }, {
    path: "/home/mobile/community",
    name: "MCommunity",
    //资源社区
    component: () => import("../viewsMobile/MCommunity.vue")
  }, {
    path: "/home/mobile/aboutUs",
    name: "MAboutUs",
    //关于我们
    component: () => import("../viewsMobile/MAboutUs.vue")
  }]
},
// 错误路径到根路径
{
  path: "*",
  redirect: "/"
}];
const VueRouterPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(to) {
  return VueRouterPush.call(this, to).catch(err => err);
};
const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return {
      x: 0,
      y: 0
    };
  }
});

//导航守卫，回调方法，在跳转前执行（编写页面组件时可临时注释如下代码）
router.beforeEach((to, from, next) => {
  next();
  // if (process.env.VUE_APP_PROJECT_NAME == "officialVebsite") {//打包部署的时候按不同环境变量匹配不同路由打包不同模块，本地开发的时候所有路由都存在可以访问方便本地开发
  //   next({ name: "Home" }); //跳转到官网
  // } else {
  //   const result = localStorage.getItem("result");
  //   next({ name: "home" }); //跳转到官网
  //   if (to.name == "Login") {
  //     if (result == 200) {
  //       next({ name: "home" }); //已登录，跳转首页
  //     } else {
  //       next(); //没登录，继续进入login页
  //     }
  //   } else {
  //     //如果进入的非login页
  //     if (result == 200) {
  //       next(); //已登录，正常进入
  //     } else {
  //       next({ name: "Login" }); //没登录，跳转到login页
  //     }
  //   }
  // }
});
export default router;