import "../../assets/css/audio.css";
export default {
  components: {},
  props: {},
  data() {
    return {
      partnerList: new Array(24),
      itemIndex: null
    };
  },
  computed: {},
  watch: {},
  beforeCreate() {},
  created() {},
  beforeMount() {},
  mounted() {},
  //方法集合
  methods: {
    mouseoverFun(index) {
      this.itemIndex = index;
    }
  }
};