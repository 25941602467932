import "core-js/modules/es.array.push.js";
import { resetPassword, getVerCode } from "../../assets/js/api/axiosApi";
import { getMapData } from "../../assets/js/api/messageMap";
import { mapState } from "vuex";
export default {
  name: "SettingCode",
  data() {
    return {
      curPhone: "",
      verCodeVal: "",
      newPasswordVal: "",
      surePasswordVal: "",
      isShowError_null: false,
      isShowError_ipone: false,
      textVal: this.$t("common.getCode"),
      isPostCode: true
    };
  },
  computed: {
    ...mapState({
      isLoginOut: state => state.isLoginOut
    }),
    realPassWord() {
      if (this.newPasswordVal == this.surePasswordVal) {
        return this.newPasswordVal;
      } else {
        return "";
      }
    }
  },
  mounted() {},
  methods: {
    displayCode() {
      this.$refs.containerSetting.style.display = "none";
    },
    onBlur() {
      let iponeRule = /^[1][3,4,5,6,7,8,9][0-9]{9}$/;
      if (!this.curPhone) {
        this.isShowError_null = true;
        this.isShowError_ipone = false;
      } else {
        if (!iponeRule.test(this.curPhone)) {
          this.isShowError_null = false;
          this.isShowError_ipone = true;
        } else {
          this.isShowError_null = false;
          this.isShowError_ipone = false;
        }
      }
    },
    getCode() {
      let scope = this;
      let time = 59;
      if (this.isPostCode && this.curPhone && !this.isShowError_null && !this.isShowError_ipone) {
        // 调取获取验证码接口
        getVerCode(this.curPhone, 2, function (info) {
          getMapData(function (messageMap) {
            scope.$Message["info"]({
              background: true,
              content: messageMap.get(info.code)
            });
          });
        });

        // 显示不同文案
        let timer = setInterval(function () {
          if (time == 0) {
            clearInterval(timer);
            scope.textVal = this.$t("common.getCode");
            scope.isPostCode = true;
          } else {
            scope.textVal = "还剩下" + time + "秒";
            time--;
            scope.isPostCode = false;
          }
        }, 1000);
      }
    },
    sureVal() {
      let scope = this;
      if (this.realPassWord) {
        resetPassword(this.curPhone, this.realPassWord, this.verCodeVal, function (info) {
          if (info.code == 10000) {
            getMapData(function () {
              // 登录时弹窗提醒
              scope.$Message["info"]({
                background: true,
                content: info.msg
              });
            });
            scope.$refs.containerSetting.style.display = "none";
            localStorage.clear(); //清除页面所有localStorage，退出登录
            let x = isNaN(scope.isLoginOut) ? 0 : scope.isLoginOut;
            scope.$store.commit("SET_LOGINOUT", x + 1);
            scope.$store.commit("SET_LOGINMODAL", true);
            if (scope.$route.name == "PersonalCenter") {
              scope.$router.push({
                name: "ImmersiveAudio"
              });
            }
          } else {
            getMapData(function () {
              // 登录时弹窗提醒
              scope.$Message["info"]({
                background: true,
                content: info.msg
              });
            });
          }
        });
      } else {
        this.$Message["info"]({
          background: true,
          content: "两次密码输入不一致"
        });
      }
    }
  }
};