export default {
  nav: {
    immersiveAudio: "Immersive Audio",
    interactiveAudio: "Interactive Audio",
    creator: "Creator",
    resources: "Resource Community",
    aboutOur: "About Us"
  },
  common: {
    open: "Open",
    openVoice: "Open the voice",
    enterPhoneNumber: "Please enter the mobile phone number",
    enterPhoneError: "Improper format of the mobile phone number",
    enterPassword: "enter password",
    forgetPassword: "Forget the password",
    accountLogin: "Account login",
    resetting: "Resetting",
    enterNewPassword: "Enter a new password",
    passwordAgain: "Enter the new password again",
    changePasswordIdentity: "When changing your password, you need to verify your identity",
    needs: "Your needs",
    needsDescription: "Leave your needs, we will get in touch with you in the first time!",
    cancel: "cancel",
    confirm: "confirm",
    freeUse: "Free use",
    operatingGuide: "operating guide",
    example: "Example",
    readMore: "read more",
    login: "Login",
    register: "Register",
    thirdPartyLogin: "Third party login",
    wechatLogin: "Wechat login",
    noAccount: "There is no account yet",
    quickRegistration: "Quick registration",
    hasAccount: "Has an account",
    loginImmediately: "Log in immediately",
    enterCode: "Enter the verification code",
    digitCode: "A 4-digit verification code",
    getCode: "Get verification code",
    // mobilePhoneNumbersAre: 'Currently, only the Chinese mainland area mobile phone numbers are supported',
    mobilePhoneNumbersAre: "Chinese Mobile phone number",
    bindingMobilePhone: "Binding mobile phone number",
    cooperativePartner: "cooperative partner",
    personalHomepage: "personal homepage",
    GoToStudio: "Go to the studio",
    logOff: "log off",
    pleaseMailbox: "Please enter the mailbox",
    mailboxFormed: "The mailbox is incorrectly formed",
    enterKeyword: "Please enter the keyword",
    copyUrl: "Copy url",
    url: "URL",
    noData: "No data",
    updatedAtAbout: "Updated at about ",
    ago: "ago",
    justUpdated: "Just updated",
    minute: "Minutes",
    hour: "Hours",
    Days: "Days",
    playVideo: "play video"
  },
  linkDescription: ["Suggestions to use the computer to experience this function, the effect is better!", "Please copy the website below and go to the computer"],
  footerNav: {
    contactUsDescription: " Contact us if you wanna explore the world of immersive audio",
    introduction: "Introduction of WANOS",
    servicePolicy: "Service Policy",
    aboutUs: "About Us",
    privacyPolicy: "Privacy Policy",
    companyProfile: "Company Profile",
    followUs: "Follow Us",
    wechatAccount: "Wechat Account",
    weiboAccount: "Weibo Account",
    contactUs: "Contact Us",
    businessCooperation: "Business Cooperation",
    businessConsulting: "Business Consulting",
    communityGroup: "Community Group",
    feedback: "Feedback",
    wechat: "Wechat",
    feedbackQuestions: "Feedback Questions",
    scanQRCode: "Scan the QR code",
    communicationGroup: "Join the community communication group",
    followWechatOfficialAccount: "Follow the wechat official account",
    shareFriends: "You can share it with your friends"
  },
  immersiveAudio: {
    soundExperience: "Marvelous Sound Experience",
    soundExperienceDescription: "Create realistic, vivid and detailed sound from all directions including head top",
    newDimension: "A new dimension of sound",
    newDimensionDescription: "High accuracy of audio scene with clear and trackable sound motion",
    entertainmentScene: "Immersive entertainment",
    entertainmentSceneDescription: "Personally inside the scene in movies, games and music.",
    WANOSMovie: " WANOS Movie",
    WANOSMovieDescription: "Wanos provides a comprehensive solution for the film industry including content creation tools, cinema equipments and services. Over 70 WANOS movies have been released and over 300 cinemas in China have adopted WANOS audio technology by 2022.",
    WANOSMusic: "WANOS Music",
    WANOSMusicDescription: "Make sound all around you. Express details of  musical instruments, emotion of singers and intention of artists.",
    WANOSCar: " WANOS Car",
    WANOSCarDescription: " Provide powerful support for automotive manufacturer. Build a complete ecosystem for vehicles. Design immersive listening space seamlessly integrated with vehicles. Create unprecedented in-car entertainment experience. Take the real world into the car with vibrant sound effect. Make more enjoyable and immersive in-car environment for drivers."
  },
  interactiveAudio: {
    feelChange: "Feel the changes of interactive audio！",
    headphoneExperience: "Put on your headphone for a better experience.",
    immediateExperience: "immediate experience",
    spatialPositioning: "Spatial Positioning",
    spatialPositioningDescription: "Out positioning technology can locate the position, direction, and distance of sound accurately in three-dimensional space. Sound trail can be clearly restored when sound moves, creating a more realistic and immersive experience.",
    interactiveExperience: "Interactive Experience",
    interactiveExperienceDescription: "WANOS spatial audio rendering engine use high-dimension sound modeling technology to create a more realistic and immersive listening environment. Sound varies with different listener direction, object distance, object position, etc.",
    soundfieldSimulation: "Soundfield Simulation",
    soundfieldSimulationDescription: "Restore a sound scene same as the real world, from living rooms to theaters, from valleys to plains.",
    gameSpatialAudio: "Game Spatial Audio",
    gameSpatialAudioDescription: [" Immersed in a more realistic experience with wanos sound effects", " Survive longer, improve your score, and win the game. Locate lurking enemies, avoid whizzing bullets, and detect nearby threats.", "  Stay focus and play longer. Reduce ear and brain fatigue by pushing the sound away from you.", "  Customize sound effects for the game."],
    spatialAudio: "AR/VR/MR Spatial Audio",
    spatialAudioDescription: [" An Immersion virtual world similar to the real world. Unlimited object rendering and interactive sound rendering.", " Better experience with higher sound quality, lower latency and real interaction.", " Space and atmosphere More realistic virtual scenes", " Dynamic rendering Customized HRTF and crosstalk cancellation for real-time  sound rendering.", "  WANOS Player Play media resources in wanos format, restore 3D audio in virtual world"]
  },
  creator: {
    soundCreators: " Who are immersive sound creators?",
    description: " Anyone, from audio enthusiasts to Grammy winners, can all create with WANOS. Out aim is to provide the best service for creative innovators.",
    webStudioDescription: " WANOS WEB STUDIO is a powerful tool for creating  and delivering immersive audio works. We've redesigned the way of creating immersive audio, making it easy for anyone to do, regardless of experience.With beautifully designed and easily customizable templates, you can create immersive audio within  minutes.",
    quickStartGuide: " Quick start guide",
    quickStartGuideDescription: ' Our quick start guide will lead you to understand the basics of panoramic sound and how to use WANOS WEB STUDIO so that you can start creation right away."',
    artist: "Artist",
    artistDescription: ["Visual presentation is just a part of the artists' stories in video production. ", "WANOS enriches videos with immersive sound, bringing a higher dimension of expression to visual scenes.", "The realistic feeling of sound touches the soul of each artist and listener, integrating perfectly with pictures, making artistic creation perfect.", "WANOS provides a highly simplified sound solution for artists, sound designers, and composers, enabling them to create three-dimensional surround sound content more conveniently and flexibly."],
    creativeDesign: "Creative Design",
    creativeDesignDescription: [" Sound design has been booming for years in many fields like fils, music, games, etc., but it's still a professional field. WANOS rolls out a brand-new panoramic cloud audio workstation that allows non-professionals to enjoy the fun of sound creation as well. ", ' WANOS makes sound creation more professional, simpler, and faster than ever before, providing stunning panoramic sound audio creation services for sound fans."'],
    amateurStudioSettings: " Amateur Studio Settings",
    amateurStudioSettingsDescription: " Just use headphones. Adjust sound's spatial position. Preview the panoramic sound effect in real time. Feel sound effect by WANOS spatial audio binaural rendering algorithm. Create immersive panoramic soundscape.\"",
    professionalSettings: "Professional Studio Settings",
    professionalSettingsDescription: '         Audio professionals can integrate WANOS into their workflow, configure audio output devices in "My Studio" section to complete speaker array setup, and start immersive panoramic sound audio creation.',
    professionalSettingsExample: " Add 4 speakers on the ceiling, then the studio evolves from a 7.1 surround environment to a 7.1.4 panoramic environment. Complete the hardware configuration that meets the requirements of panoramic sound. The typical speaker layout for 7.1.4 has 3 speakers in the front, 2 speakers in side surrounds, 2 speakers in rear surrounds, 4 speakers overhead, and 1 subwoofer.",
    coolThings: " You can do cool things in WANOS.",
    creativity: "  Explore the infinite creativity in out resource community."
  },
  resources: ["A huge sound effect library, large numbers of music templates and resources", "stimulating your inspiration during your artistic creation."],
  aboutOur: {
    companyIntroduction: "Company Introduction",
    aboutUs: " WANOS Audio Technology is a world-leading audio technology pioneer that integrates sound objects and spatial audio technologies to create a new three-dimensional immersive listening experience. Listeners can experience creative intention of artists, and feel a realistic world through immersive sound effects.",
    foundingTeam: "Founding team ",
    foundingTeamDescription: [" WANOS Audio Technology's founding team consists of a number of internationally renowned audio technology experts with over 20 years of experience in international audio technology research, standardization, and industrialization. They are leaders in audio technology innovation, the developers of national standards, international standards, and industry standards, and the pioneers of immersive audio and spatial audio technology.", " The founder, Dr. Pan Xingde, is one of the earliest explorers of immersive audio and spatial audio technology. He is the founding chair of the AVS Audio Group and the current co-chair of the AVS Audio Group and the convener of the IEEE VR Audio Standard.\n"],
    CEOName: " Pan Xingde",
    CEOJob: "Founder & CEO",
    ourInvestors: "Our investors",
    CEODescription: [" Beijing University of Posts and Telecommunications Doctor of Science", " Founding group leader of the AVS Audio Group, China Audio Visual Standardization Group", ' In charge of the core technology research and development, standardization, and industrialization of immersive audio and spatial audio"']
  }
};