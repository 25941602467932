var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    ref: "containerSetting",
    staticClass: "containerSetting"
  }, [_c('div', {
    staticClass: "settingTitle"
  }, [_vm._v(_vm._s(_vm.$t("common.forgetPassword")))]), _c('div', {
    staticClass: "xArea",
    on: {
      "click": _vm.displayCode
    }
  }, [_c('p', {
    staticClass: "xIcon"
  }, [_c('svg-icon', {
    attrs: {
      "icon-class": "close",
      "class-name": "f16"
    }
  })], 1)]), _c('div', {
    staticClass: "lineTop"
  }), _c('div', {
    staticClass: "settingTitle_1"
  }, [_vm._v(">" + _vm._s(_vm.$t("common.changePasswordIdentity")))]), _c('div', {
    staticClass: "currentCode"
  }, [_vm._v(_vm._s(_vm.$t("common.enterPhoneNumber")))]), _c('div', {
    staticClass: "input_code_1"
  }, [_c('p', {
    staticClass: "eightsix"
  }, [_vm._v("+86    |")]), _c('Input', {
    staticClass: "input_phone",
    attrs: {
      "maxlength": "11",
      "placeholder": _vm.$t('common.mobilePhoneNumbersAre')
    },
    on: {
      "on-blur": _vm.onBlur
    },
    model: {
      value: _vm.curPhone,
      callback: function ($$v) {
        _vm.curPhone = $$v;
      },
      expression: "curPhone"
    }
  })], 1), _vm.isShowError_ipone ? _c('div', {
    staticClass: "showError_1"
  }, [_vm._v(" " + _vm._s(_vm.$t("common.enterPhoneError")) + " ")]) : _vm._e(), _vm.isShowError_null ? _c('div', {
    staticClass: "showError_1"
  }, [_vm._v(" " + _vm._s(_vm.$t("common.enterPhoneNumber")) + " ")]) : _vm._e(), _c('div', {
    staticClass: "writeVerCode"
  }, [_vm._v(_vm._s(_vm.$t("common.enterCode")))]), _c('div', {
    staticClass: "input_Ver"
  }, [_c('Input', {
    staticClass: "input_code_1_1",
    attrs: {
      "maxlength": "4",
      "placeholder": _vm.$t('common.digitCode')
    },
    model: {
      value: _vm.verCodeVal,
      callback: function ($$v) {
        _vm.verCodeVal = $$v;
      },
      expression: "verCodeVal"
    }
  }), _c('div', {
    class: ['tac', _vm.isPostCode ? 'verButton button_T' : 'verButton button_F'],
    attrs: {
      "type": "primary"
    },
    on: {
      "click": _vm.getCode
    }
  }, [_vm._v(" " + _vm._s(_vm.textVal) + " ")])], 1), _c('div', {
    staticClass: "newCode"
  }, [_vm._v("设置密码")]), _c('div', {
    staticClass: "input_code_2"
  }, [_c('Input', {
    staticClass: "input_code_1_1",
    attrs: {
      "placeholder": _vm.$t('common.enterNewPassword'),
      "type": "password",
      "password": ""
    },
    model: {
      value: _vm.newPasswordVal,
      callback: function ($$v) {
        _vm.newPasswordVal = $$v;
      },
      expression: "newPasswordVal"
    }
  })], 1), _c('div', {
    staticClass: "sureNewCode"
  }, [_vm._v("确认密码")]), _c('div', {
    staticClass: "input_code_3"
  }, [_c('Input', {
    staticClass: "input_code_1_1",
    attrs: {
      "placeholder": _vm.$t('common.passwordAgain'),
      "type": "password",
      "password": ""
    },
    model: {
      value: _vm.surePasswordVal,
      callback: function ($$v) {
        _vm.surePasswordVal = $$v;
      },
      expression: "surePasswordVal"
    }
  })], 1), _c('div', {
    staticClass: "lineBottom"
  }), _c('div', {
    staticClass: "cancelButton tac",
    on: {
      "click": _vm.displayCode
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("common.cancel")) + " ")]), _c('div', {
    staticClass: "sureButton tac",
    on: {
      "click": _vm.sureVal
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("common.confirm")) + " ")])]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };