import HeaderTop from "../components/common/Header";
import FooterBottom from "../components/common/Footer";
import CooperativePartner from "../components/common/CooperativePartner";
import { mapState } from "vuex";
import { checkDevice } from "../assets/js/public/math";
export default {
  components: {
    HeaderTop,
    FooterBottom,
    CooperativePartner
  },
  props: {},
  data() {
    return {
      isShow: false,
      routePath: "",
      isAccount: false,
      isReviseName: false,
      isWxLogin: false,
      dialogVisibleActivity: false
    };
  },
  computed: {
    ...mapState(["isPersonWin"])
  },
  watch: {
    $route() {
      this.routePath = this.$route.path;
      switch (this.routePath) {
        case "/home/Resources":
          this.isShow = false;
          break;
        case "/home/PersonalCenter":
          this.isShow = false;
          break;
        default:
          this.isShow = true;
      }
    },
    isPersonWin() {
      this.isAccount = this.isPersonWin;
    }
  },
  beforeCreate() {},
  created() {
    // 已刷新，针对手机端，根目录刷新后，需将路由重定向到手机端首页
    if (localStorage.getItem("isRefresh") == "已刷新") {
      let isMobile = checkDevice(false);
      if (isMobile) {
        checkDevice(true);
      }
    }
    this.routePath = this.$route.path;
    switch (this.routePath) {
      case "/home/Resources":
        this.isShow = false;
        break;
      case "/home/PersonalCenter":
        this.isShow = false;
        break;
      default:
        this.isShow = true;
    }
  },
  beforeMount() {},
  mounted() {
    //音浪计划活动圆满完成，关闭以下代码
    // setTimeout(()=>{
    // if (localStorage.getItem("popuptime")){
    //   if (this.getDate() !== localStorage.getItem("popuptime")) {
    //     this.dialogVisibleActivity = true
    //   }
    // } else {
    //   this.dialogVisibleActivity = true
    // }
    // }, 1000)
  },
  //方法集合
  methods: {
    linkActivity() {
      // let url = this.$router.resolve({
      //   name: "Activity",
      // });
      // window.open("https://mp.weixin.qq.com/s/VGrudBhAYdhhDQqYHxogWw", "_blank");  //第一次跳转的公众号
      window.open("https://mp.weixin.qq.com/s?__biz=MzAwNDk1NjYzMg==&mid=2247488601&idx=1&sn=e828a92375e04c663fb239c598ce4675&chksm=9b255ceeac52d5f867cbb1ca11ffe2cbda7a13ea0d62d6deb8b37810eb6fcd4a4ee29c2b3d6f&token=581963066&lang=zh_CN#rd", "_blank");

      // window.open(url.href, "_blank");

      this.handleCloseActivity();
    },
    handleCloseActivity() {
      this.dialogVisibleActivity = false;
      // let date = this.getDate()
      // localStorage.setItem("popuptime", date)
    },
    getDate() {
      let date = new Date();
      let y = date.getFullYear(); //年
      let m = date.getMonth();
      let d = date.getDate();
      return y + "年" + m + "月" + d + "日";
    }
  }
};