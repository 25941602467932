import "core-js/modules/es.array.push.js";
import "core-js/modules/web.dom-exception.stack.js";
import "../../assets/css/index.css";
import { login } from "../../assets/js/api/axiosApi";
import { getMapData } from "../../assets/js/api/messageMap";
export default {
  name: "LoginCom",
  props: ["isModal", "changeLoginStyle", "changeReStyle"],
  data() {
    return {
      iphoneVal: null,
      passwordVal: null,
      isShowError_null: false,
      isShowError_iphone: false,
      fromPath: "",
      isWxLogin: false,
      passwordWrong: ""
    };
  },
  watch: {
    isModal() {
      if (!this.isModal) {
        this.iphoneVal = "";
        this.passwordVal = "";
        this.isShowError_null = false;
        this.isShowError_iphone = false;
        this.passwordWrong = "";
      }
    }
  },
  created() {},
  mounted() {},
  methods: {
    // 只能输入英文、数字
    btKeyDown(e) {
      e.target.value = e.target.value.replace(/\s*/g, "");
    },
    //限制输入特殊字符
    btKeyUp(e) {
      e.target.value = e.target.value.replace(/[`~!@#$%^&*()_\-+=<>?:"{}|,./;'\\[\]·~！@#￥%……&*（）——\-+={}|《》？：“”【】、；‘'，。、]/g, "");
    },
    passwordChange() {
      this.passwordVal = this.passwordVal.replace(/\s+/g, "");
      this.passwordWrong = this.isPassword(this.passwordVal);
    },
    accountLoginClick() {
      this.isWxLogin = false;
      this.changeLoginStyle(false);
    },
    wxLoginClick() {
      const cssCode = `
      .impowerBox .title {opacity: 0;}
      .impowerBox .info {opacity: 0;}
      `;
      const cssBase64 = btoa(cssCode);
      let href = window.location.href;
      let path = encodeURIComponent(href);
      let appid = process.env.VUE_APP_APPID;
      let WxLogin = window.WxLogin;
      new WxLogin({
        self_redirect: false,
        id: "login_container",
        appid: appid,
        scope: "snsapi_login",
        redirect_uri: path,
        state: "white",
        style: "",
        href: "data:text/css;base64," + cssBase64
      });
      this.changeLoginStyle(true);
      this.isWxLogin = true;
    },
    reset() {
      this.$parent.switchState("reset");
    },
    async register() {
      await this.$parent.switchState("register");
      this.changeReStyle(false);
    },
    onBlur() {
      let iponeRule = /^[1][3,4,5,6,7,8,9][0-9]{9}$/;
      if (!this.iphoneVal) {
        this.isShowError_null = true;
        this.isShowError_iphone = false;
      } else {
        if (!iponeRule.test(this.iphoneVal)) {
          this.isShowError_null = false;
          this.isShowError_iphone = true;
        } else {
          this.isShowError_null = false;
          this.isShowError_iphone = false;
        }
      }
    },
    isPassword(password) {
      //replace(/\s*/g, '')
      // console.log(password)
      const emailRule = /^\w{6,20}$/;
      let isWrong = emailRule.test(password);
      if (password == "") {
        return "密码不能为空";
      } else {
        //let isWrong = isPassword(this.passwordVal)
        if (!isWrong) {
          return "密码为6-20字符，由英文大小写、数字组成";
        } else {
          return "";
        }
      }
    },
    loginBut() {
      let scope = this;
      login(this.iphoneVal, this.passwordVal, function (info) {
        getMapData(function () {
          // 登录时弹窗提醒

          scope.$Message["info"]({
            background: true,
            content: info.msg
          });

          // 登录成功的状态
          if (info.code == 200 || info.code == 10000) {
            //localStorage添加，将登录成功的用户信息存入

            let vdata = info.data;

            //这个result == 200 是登录状态
            localStorage.setItem("result", 200);
            localStorage.setItem("email", vdata.user.email);
            localStorage.setItem("nation", vdata.user.nation);
            localStorage.setItem("phone", vdata.user.mobile);
            localStorage.setItem("id", vdata.user.id);
            localStorage.setItem("name", vdata.user.username);
            localStorage.setItem("token", vdata.token);

            // 页面跳转主页
            // scope.$router.go(-1);
            scope.$store.commit("SET_LOGINMODAL", false);
            scope.$store.commit("setAvatarUrl", vdata.user.avatar);
            scope.iphoneVal = "";
            scope.passwordVal = "";
            setTimeout(() => {
              if (scope.$route.query.url) {
                // let token = localStorage.getItem("token");
                window.open(scope.$route.query.url, "_blank");
              }
            }, 500);
            scope.$router.push({
              path: scope.$route.fullPath
            });
          }
        });
      });
    }
  }
};