import "core-js/modules/es.array.push.js";
/*
 * @Author: ZJJ
 * @Date: 2023-10-12 18:08:53
 * @LastEditTime: 2024-05-09 11:24:57
 * @FilePath: /website/src/assets/js/public/math.js
 * @Description: 维护公共方法
 */
import router from "../../../router/index";

// 判断当前登录设备，并跳转不同路由
// 注：下方isOpenCom判断是为了区分特定路由，不强制跳转到根目录，但仍分别写进isMobile判断，是因对应组件内部也许获取设备信息
// Synopsis:对应公司介绍；Agreement：对应服务协议及隐私协议
let isFirstEnter = true;
let checkDevice = function (isJump) {
  let currentUrl = window.location.href;
  const userAgent = window.navigator.userAgent.toLowerCase();
  const isMobile = /iphone|ipad|ipod|android|blackberry|mini|windows\sce|palm/i.test(userAgent);
  if (isFirstEnter) {
    if (isJump) {
      if (isMobile) {
        let isOpenCom = currentUrl.includes("Play") || currentUrl.includes("Synopsis") || currentUrl.includes("Agreement");
        if (isOpenCom) {
          window.open(currentUrl);
          isFirstEnter = false;
        } else {
          router.push("/home/mobile/immerse");
        }
        // console.log("进入手机端");
      } else {
        let isOpenCom = currentUrl.includes("Synopsis") || currentUrl.includes("Agreement");
        if (isOpenCom) {
          window.open(currentUrl);
        } else {
          router.push("/");
        }
        // console.log("进入PC端");
      }
    }
  }
  return isMobile;
};
export { checkDevice };