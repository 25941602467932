var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    ref: "containerCode",
    staticClass: "containerCode"
  }, [_c('div', {
    staticClass: "settingTitle"
  }, [_vm._v("修改密码")]), _c('div', {
    staticClass: "xArea",
    on: {
      "click": _vm.displayCode
    }
  }, [_c('p', {
    staticClass: "xIcon"
  }, [_c('svg-icon', {
    attrs: {
      "icon-class": "close",
      "class-name": "f16"
    }
  })], 1)]), _c('div', {
    staticClass: "lineTop"
  }), _c('div', {
    staticClass: "currentCode"
  }, [_vm._v("当前密码")]), _c('div', {
    staticClass: "forgetCode",
    on: {
      "click": _vm.forgetCode
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("common.forgetPassword")) + "？ ")]), _c('div', {
    staticClass: "input_code_1"
  }, [_c('Input', {
    staticClass: "input_code_1_1",
    attrs: {
      "placeholder": "输入当前密码",
      "type": "password",
      "password": ""
    },
    model: {
      value: _vm.curPasswordVal,
      callback: function ($$v) {
        _vm.curPasswordVal = $$v;
      },
      expression: "curPasswordVal"
    }
  })], 1), _c('div', {
    staticClass: "newCode"
  }, [_vm._v("新密码")]), _c('div', {
    staticClass: "input_code_2"
  }, [_c('Input', {
    staticClass: "input_code_1_1",
    attrs: {
      "placeholder": _vm.$t('common.enterNewPassword'),
      "type": "password",
      "password": ""
    },
    model: {
      value: _vm.newPasswordVal,
      callback: function ($$v) {
        _vm.newPasswordVal = $$v;
      },
      expression: "newPasswordVal"
    }
  })], 1), _c('div', {
    staticClass: "sureNewCode"
  }, [_vm._v("确认新密码")]), _c('div', {
    staticClass: "input_code_3"
  }, [_c('Input', {
    staticClass: "input_code_1_1",
    attrs: {
      "placeholder": _vm.$t('common.passwordAgain'),
      "type": "password",
      "password": ""
    },
    model: {
      value: _vm.surePasswordVal,
      callback: function ($$v) {
        _vm.surePasswordVal = $$v;
      },
      expression: "surePasswordVal"
    }
  })], 1), _c('div', {
    staticClass: "lineBottom"
  }), _c('div', {
    staticClass: "cancelButton tac",
    on: {
      "click": _vm.displayCode
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("common.cancel")) + " ")]), _c('div', {
    staticClass: "sureButton tac",
    on: {
      "click": _vm.sureVal
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("common.confirm")) + " ")])]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };