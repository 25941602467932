var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "container_box"
  }, [_c('div', {
    staticClass: "title_iphone"
  }, [_vm._v(_vm._s(_vm.$t("common.enterPhoneNumber")))]), _c('div', {
    staticClass: "input_iphone"
  }, [_c('p', {
    staticClass: "valStyle"
  }, [_vm._v("+86    |")]), _c('Input', {
    staticClass: "input_1",
    attrs: {
      "maxlength": "11",
      "placeholder": _vm.$t('common.mobilePhoneNumbersAre'),
      "clearable": ""
    },
    on: {
      "on-blur": _vm.onBlur
    },
    model: {
      value: _vm.iphoneVal,
      callback: function ($$v) {
        _vm.iphoneVal = $$v;
      },
      expression: "iphoneVal"
    }
  })], 1), _vm.isShowError_ipone ? _c('div', {
    staticClass: "showError_1"
  }, [_vm._v(" " + _vm._s(_vm.$t("common.enterPhoneError")) + " ")]) : _vm._e(), _vm.isShowError_null ? _c('div', {
    staticClass: "showError_1"
  }, [_vm._v(" " + _vm._s(_vm.$t("common.enterPhoneNumber")) + " ")]) : _vm._e(), _c('div', {
    staticClass: "ver_text"
  }, [_vm._v(_vm._s(_vm.$t("common.enterCode")))]), _c('div', {
    staticClass: "input_ver"
  }, [_c('Input', {
    staticClass: "input_2",
    attrs: {
      "placeholder": _vm.$t('common.digitCode'),
      "maxlength": "4",
      "autocomplete": "new-password"
    },
    model: {
      value: _vm.verCodeVal,
      callback: function ($$v) {
        _vm.verCodeVal = $$v;
      },
      expression: "verCodeVal"
    }
  })], 1), _c('div', {
    class: [_vm.isPostCode ? 'button_get button_T' : 'button_get button_F', 'tac'],
    attrs: {
      "type": "primary"
    },
    on: {
      "click": _vm.getCode
    }
  }, [_vm._v(" " + _vm._s(_vm.textVal) + " ")]), _c('div', {
    staticClass: "code_text"
  }, [_vm._v(_vm._s(_vm.$t("common.enterNewPassword")))]), _c('div', {
    staticClass: "input_code"
  }, [_c('Input', {
    staticClass: "input_2",
    attrs: {
      "type": "password",
      "password": "",
      "autocomplete": "new-password",
      "maxlength": "20"
    },
    on: {
      "on-change": _vm.passwordChange
    },
    nativeOn: {
      "keyup": function ($event) {
        return _vm.btKeyUp.apply(null, arguments);
      },
      "keydown": function ($event) {
        return _vm.btKeyDown.apply(null, arguments);
      }
    },
    model: {
      value: _vm.passwordVal,
      callback: function ($$v) {
        _vm.passwordVal = $$v;
      },
      expression: "passwordVal"
    }
  }), _c('p', [_vm._v(_vm._s(_vm.passwordWrong))])], 1), _c('Button', {
    staticClass: "button_iphone",
    attrs: {
      "type": "primary"
    },
    on: {
      "click": _vm.reset
    }
  }, [_vm._v(_vm._s(_vm.$t("common.resetting")))]), _c('div', {
    staticClass: "footerEnter"
  }, [_c('p', {
    staticClass: "footer_1"
  }, [_vm._v(_vm._s(_vm.$t("common.hasAccount")) + "？")]), _c('p', {
    staticClass: "footer_2",
    on: {
      "click": _vm.login
    }
  }, [_vm._v(_vm._s(_vm.$t("common.loginImmediately")))])])], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };