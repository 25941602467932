import publicAxios from "./axios.js";
// 社区列表
export function getCommunityList(data) {
  return publicAxios({
    url: "community/list",
    method: "get",
    params: data
  });
}

// 活动作品列表
export function getActivityProjectList(data) {
  return publicAxios({
    url: "activity/projectlist",
    method: "get",
    params: data
  });
}

//  类型列表
export function getCate() {
  return publicAxios({
    url: "community/cate",
    method: "get"
  });
}

//  获取个人信息
export function getUserInfo(params) {
  return publicAxios({
    url: "/home/user",
    method: "get",
    params: params
  });
}
// 获取个人资源列表
export function getUserResourcesList(params) {
  return publicAxios({
    url: "community/user",
    method: "get",
    params: params
  });
}

// 获取个人资源列表
export function getUserLikeResourcesList(params) {
  return publicAxios({
    url: "community/stars",
    method: "get",
    params: params
  });
}

// 删除资源
export function getResourcesDelete(data) {
  return publicAxios({
    url: "community/remove",
    method: "post",
    data: data
  });
}
// 参加活动的作品
export function deleteActivityProject(data) {
  return publicAxios({
    url: "activity/remproject",
    method: "post",
    data: data
  });
}
//

// 获取资源详情
export function getResourcesDetail(params) {
  return publicAxios({
    url: "community/detail",
    method: "get",
    params: params
  });
}

// “我的项目”获取详情
export function getUserDetail(params) {
  return publicAxios({
    url: "community/userDetail",
    method: "get",
    params: params
  });
}

// “喜欢的项目”获取详情
export function getUserStarDetail(params) {
  return publicAxios({
    url: "community/userStarDetail",
    method: "get",
    params: params
  });
}

// 点赞
export function handleStar(data) {
  return publicAxios({
    url: "community/star",
    method: "post",
    data: data
  });
}

// 正在关注/ 关注列表
export function getFollow(params) {
  return publicAxios({
    url: "home/following",
    method: "get",
    params: params
  });
}

// 粉丝列表
export function getFans(params) {
  return publicAxios({
    url: "home/followers",
    method: "get",
    params: params
  });
}
// 关注/取消
export function handleFollow(data) {
  return publicAxios({
    url: "home/follow",
    method: "post",
    data: data
  });
}

// 获取分享二维码
export function getShareCode(params) {
  return publicAxios({
    url: "community/share",
    method: "get",
    params: params
  });
}

// 获取首页图片
export function getIndexImg(params) {
  return publicAxios({
    url: "index/index",
    method: "get",
    params: params
  });
}
export function handleFeeback(data) {
  return publicAxios({
    url: "/index/feedback",
    method: "post",
    data: data
  });
}
// 验证token

export function verificationToken() {
  return publicAxios({
    url: "/user/user",
    method: "get"
  });
}