/**
 * Upper case first char
 * @param {String} string
 */

/**
 * 时间戳转化为 年月日 是分秒
 */
import i18n from "../i18n";
export function switchTime(lastTimeVal) {
  lastTimeVal = lastTimeVal * 1000;
  // 数据更新时间
  let lastTime = new Date(lastTimeVal);

  // 本地当前时间
  let currentTime = new Date();

  // 数据更新的年份及当前年份
  let lastYear = lastTime.getFullYear();
  let currentYear = currentTime.getFullYear();

  // 数据更新的日期 月/日
  let lastMonth = lastTime.getMonth() + 1;
  let lastDay = lastTime.getDate();
  if (lastMonth < 10) {
    lastMonth = "0" + lastMonth;
  }
  if (lastDay < 10) {
    lastDay = "0" + lastDay;
  }

  //当前时间与数据更新时间相差的毫秒数
  let differTime = currentTime - lastTime;

  //当前时间与数据更新时间相差 分钟/小时/天数 并取整
  let afterMin = parseInt(differTime / (1000 * 60));
  let afterHour = parseInt(differTime / (1000 * 60 * 60));
  let afterDay = parseInt(differTime / (1000 * 60 * 60 * 24));
  let updatedAtAbout = i18n.t("common.updatedAtAbout");
  let ago = i18n.t("common.ago");
  if (afterMin < 1) {
    // 刚刚更新
    return i18n.t("common.justUpdated");
  } else if (afterMin >= 1 && afterMin < 60) {
    // 小于1小时显示XX分钟前
    return updatedAtAbout + afterMin + i18n.t("common.minute") + ago;
  } else if (afterMin >= 60 && afterHour < 24) {
    // 小于1天显示XX小时前
    return updatedAtAbout + afterHour + i18n.t("common.hour") + ago;
  } else if (afterHour >= 24 && afterDay < 30) {
    // 大于1天 小于30天显示XX天前
    return updatedAtAbout + afterDay + i18n.t("common.Days") + ago;
  } else if (afterDay >= 30) {
    if (lastYear == currentYear) {
      // 大于30天不超过当年显示 XX月XX日
      return updatedAtAbout + lastMonth + "." + lastDay;
    } else {
      // 超过当年 一律显示XX年XX月XX日，忽律以上逻辑
      return updatedAtAbout + lastYear + "." + lastMonth + "." + lastDay;
    }
  }
}

/**
 * 秒 转化为 时分秒
 * @param {Number} s  秒
 * @returns  String
 */
export function secTotime(s) {
  let t = "";
  if (s > -1) {
    let hour = Math.floor(s / 3600);
    let min = Math.floor(s / 60) % 60;
    let sec = Math.floor(s % 60);
    if (hour < 10) {
      t = "0" + hour + ":";
    } else {
      t = hour + ":";
    }
    if (min < 10) {
      t += "0";
    }
    t += min + ":";
    if (sec < 10) {
      t += "0";
    }
    // t += sec.toFixed(0)
    t += sec;
  }
  return t;
}

/**
 * 根据图片路径获取缩略图
 * @param { String } url 图片元素路径
 * @param { Number } w 缩略图宽
 * @param { Number } h 缩略图高
 * @example 16062380347896.jpg => 16062380347896.jpg?x-oss-process=image/resize,m_fill,h_100,w_100,limit_0
 */
export function smallImg(url, w, h) {
  let simgurl = "";
  if (!url) return simgurl;
  // cos 图片链接
  simgurl = `${url}?imageMogr2/format/webp/thumbnail/${w * window.devicePixelRatio}x${h * window.devicePixelRatio}`;
  return simgurl;
}