// 接口管理 登录及首页部分
import "../proto/protobuf";
import { publicAxios, exportAxios } from "./public_axios";
import Vue from "../../../main";

// 如下均为接口

// 微信登录  /base/wechat/login
var wxLogin = function (code, iphoneVal, passwordVal, verCodeVal, callback) {
  let message_login = {};
  message_login.nation = "+86";
  message_login.mobile = iphoneVal;
  message_login.password = passwordVal;
  message_login.code = verCodeVal;
  message_login.auth_code = code;
  publicAxios(message_login, "base/wechat/login", "post", function (info) {
    callback(info.data);
  });
};

// 登录
var login = function (iphoneVal, passwordVal, callback) {
  let message_login = {};
  message_login.nation = "+86";
  message_login.mobile = iphoneVal;
  message_login.password = passwordVal;
  publicAxios(message_login, "base/login", "post", function (info) {
    callback(info.data);
  });
};

// 注册
var register = function (iphoneVal, passwordVal, verCodeVal, callback) {
  let message_register = {};
  message_register.nation = "+86";
  message_register.mobile = iphoneVal;
  message_register.password = passwordVal;
  message_register.code = verCodeVal;
  publicAxios(message_register, "base/register", "post", function (info) {
    callback(info.data);
  });
};

// 获取验证码
var getVerCode = function (iphoneVal, foruse, callback) {
  // let message_smscode = new proto.network.message.c2g_get_smscode();
  let message_smscode = {};
  message_smscode.nation = "+86";
  message_smscode.mobile = iphoneVal;
  publicAxios(message_smscode, "base/sendsms", "post", function (info) {
    callback(info.data);
  });
};

// 重置密码
var resetPassword = function (iphoneVal, passwordVal, verCodeVal, callback) {
  let message_reset = {};
  message_reset.nation = "+86";
  message_reset.mobile = iphoneVal;
  message_reset.password = passwordVal;
  message_reset.code = verCodeVal;
  publicAxios(message_reset, "base/reset", "post", function (info) {
    callback(info.data);
  });
};

//---- 通过旧密码 申请重置密码 ----
var changePassword = function (password, newpassword, callback) {
  let message_changePassword = {};
  message_changePassword.password = password;
  message_changePassword.newPassword = newpassword;
  publicAxios(message_changePassword, "user/password", "put", function (info) {
    callback(info.data);
  });
};
var getBaseUserInfo = function (callback) {
  let message_getBaseUserInfo = {};
  publicAxios(message_getBaseUserInfo, "user/user", "get", function (info) {
    callback(info.data);
  });
};

// 修改用户名
var changeusername = function (newname, id, callback) {
  let message_changeusername = {};
  message_changeusername.username = newname;
  message_changeusername.id = id;
  publicAxios(message_changeusername, "user/user", "put", function (info) {
    callback(info.data);
  });
};

// 使用此模板时调用
var copyProject = function (projectid, taskid, creatorid, callback) {
  let message_copyProject = {
    arr: [{
      id: projectid,
      taskid,
      creatorid
    }]
  };
  publicAxios(message_copyProject, "project/copy", "post", function (info) {
    callback(info.data);
  });
};

// 进入项目
var enterProject = function (projectid, callback) {
  let message_enterProject = {};
  message_enterProject.id = projectid;
  publicAxios(message_enterProject, "project/editor", "post", function (info) {
    callback(info.data);
  });
};
const getDetailUserInfo = function (callback) {
  let message_initHomepage = {};
  publicAxios(message_initHomepage, "project/list", "get", function (info) {
    callback(info.data);
  });
};
const publishProject = function (params, callback) {
  let message_publish = params;
  publicAxios(message_publish, "community/create", "post", function (info) {
    callback(info.data);
    if (info.data.code == 10000) {
      // signpoint_pushProject(params.project_id,params.title);
    }
  });
};
const getExportProjectList = function (callback) {
  // return;
  let message_export = {};
  exportAxios(message_export, "export/communitylist", "get", function (info) {
    callback && callback(info.data);
  });
};
const initHomepage = function (callback) {
  let message_initHomepage = {};
  publicAxios(message_initHomepage, "project/list", "get", function (info) {
    callback(info.data);

    // if (info.data.code == 10000) {
    // }
  });
};

// 获取播放数据
var serverType = process.env.VUE_APP_SERVER_TYPE;
let basicUrl;
let roomHttpUrl;
let apiUrl;
// serverType = 5
// 接口basicUrl设定
if (serverType == 1) {
  //发布线上连接用此
  basicUrl = "api.wanos.cc:9091";
  roomHttpUrl = "https://" + basicUrl + "/";
  apiUrl = "https://api.wanos.cc/";
} else if (serverType == 2) {
  //本地连接线上用此
  basicUrl = "air-api.wanos.vip:9091";
  roomHttpUrl = "https://" + basicUrl + "/";
  apiUrl = "https://air-api.wanos.vip/";
} else if (serverType == 5) {
  //本地连接线上用此
  basicUrl = "beer-api.wanos.vip:9091";
  roomHttpUrl = "https://" + basicUrl + "/";
  apiUrl = "https://beer-api.wanos.vip/";
} else if (serverType == 6) {
  //本地连接线上用此
  basicUrl = "api-pre.wanos.cc:9091";
  roomHttpUrl = "https://" + basicUrl + "/";
  apiUrl = "https:/api-pre.wanos.cc/";
} else {
  basicUrl = "192.168.1.224:8122";
  roomHttpUrl = "http://" + basicUrl + "/";
  apiUrl = "http://192.168.1.224:8123/"; //忠林电脑
}
var playoneproject = function (userid, taskid, projectid, callback) {
  let fd = new FormData();
  var ajax = new XMLHttpRequest();
  // console.log(userid, taskid, projectid);

  fd.append("userid", userid); //用户ID 必填
  fd.append("taskid", taskid); //项目ID 必填
  fd.append("projectid", projectid); //项目ID 必填

  // ajax.responseType = "arraybuffer"; // 坑点！
  var playUrl = roomHttpUrl + "outplay";
  ajax.open("POST", playUrl, true);
  let proto = window.proto;
  ajax.onload = function () {
    // console.log(ajax.response, "-----------------");
    var jsonData = JSON.parse(ajax.response);
    var reciveMessage = proto.network.message.g2c_editor_init.deserializeBinary(
    // ajax.response
    jsonData.data);
    let finals = reciveMessage.toObject();
    callback && callback(finals);
  };
  ajax.send(fd);
};
var uploadHeadImg = function (file, path, callback) {
  // console.log(e);
  // debugger
  // let files = [...e.target.files];
  // let file = files[0];
  let fileTypes = [".webp", ".jpeg", ".jpg", ".png"]; //文件后缀只能是这
  let filePath = path; //返回的是上传文件的路径名 例如：E:\xx\xxx.jpg
  //当括号里面的值为0、空字符、false 、null 、undefined的时候就相当于false
  if (filePath) {
    let isNext = false;
    let fileEnd = filePath.substring(filePath.lastIndexOf(".")); //截取文件名的后缀
    for (let i = 0; i < fileTypes.length; i++) {
      if (fileTypes[i] == fileEnd) {
        isNext = true;
        break;
      }
    }
    if (!isNext) {
      Vue.$Message["info"]({
        background: true,
        content: "不接受此文件类型"
      });
      path = ""; //如果文件后缀名不符合，清空已选择文件
      return false;
    } else {
      //callback();
    }
  } else {
    return false;
  }

  /* FormData 是表单数据类 */
  let fd = new FormData();
  let ajax = new XMLHttpRequest();
  /* 把文件添加到表单里 */
  fd.append("file", file);
  var uploadVideoUrl = apiUrl + "user/avatar/upload";
  console.log(uploadVideoUrl);
  ajax.open("post", uploadVideoUrl, true);
  //Vue.$store.commit("setIsLoading", true);
  ajax.setRequestHeader("token", localStorage.getItem("token"));
  ajax.onload = function () {
    // console.log('发送上传文件成功')
    //console.log(ajax)

    Vue.$Message["info"]({
      background: true,
      content: JSON.parse(ajax.responseText).msg
    });
    callback && callback(JSON.parse(ajax.responseText));
    //Vue.$store.commit("setIsLoading", false);
  };
  ajax.send(fd);
};
const uploadCoverImg = function (file, path, callback) {
  // console.log(e);
  // debugger
  // let files = [...e.target.files];
  // let file = files[0];
  // console.log(file);
  let fileTypes = [".webp", ".jpg", ".jpeg", ".png"]; //文件后缀只能是这
  let filePath = path; //返回的是上传文件的路径名 例如：E:\xx\xxx.jpg
  //当括号里面的值为0、空字符、false 、null 、undefined的时候就相当于false
  if (filePath) {
    let isNext = false;
    let fileEnd = filePath.substring(filePath.lastIndexOf(".")); //截取文件名的后缀
    for (let i = 0; i < fileTypes.length; i++) {
      if (fileTypes[i] == fileEnd) {
        isNext = true;
        break;
      }
    }
    if (!isNext) {
      Vue.$Message["info"]({
        background: true,
        content: "不接受此文件类型"
      });
      path = ""; //如果文件后缀名不符合，清空已选择文件
      return false;
    } else {
      //callback();
    }
  } else {
    return false;
  }

  /* FormData 是表单数据类 */
  let fd = new FormData();
  let ajax = new XMLHttpRequest();
  /* 把文件添加到表单里 */
  fd.append("file", file);
  var uploadVideoUrl = apiUrl + "file/upload";
  ajax.open("post", uploadVideoUrl, true);
  //Vue.$store.commit("setIsLoading", true);
  ajax.setRequestHeader("token", localStorage.getItem("token"));
  ajax.onload = function () {
    // console.log('发送上传文件成功')
    //console.log(ajax)

    Vue.$Message["info"]({
      background: true,
      content: JSON.parse(ajax.responseText).msg
    });
    callback && callback(JSON.parse(ajax.responseText));
    //Vue.$store.commit("setIsLoading", false);
  };
  ajax.send(fd);
};
export { login, register, getVerCode, resetPassword, changePassword, getBaseUserInfo, changeusername, copyProject, enterProject, playoneproject, wxLogin, uploadHeadImg,
//改头像
getDetailUserInfo,
//获取项目列表
uploadCoverImg,
//改封面
publishProject,
//发布作品
initHomepage,
//项目列表
getExportProjectList //正在合成的项目列表
};